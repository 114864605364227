<template>
  <v-card-title class="pl-0 pb-0" style="text-transform: uppercase; font-weight: bold">
    Vérifications </v-card-title>
  <v-col cols="12" class="pa-0">
    <Vue3EasyDataTable v-if="formData.CLD_CHECK_LIST_JSON_GESTION" :rows-per-page="200" hide-footer
      :headers="get_tab_header" :items="formData.CLD_CHECK_LIST_JSON_GESTION"
      :table-height="formData.CLD_CHECK_LIST_JSON_GESTION.length * 61"  empty-message="Aucune vérification prédéfini">
      <template #item-CLD_QTE_DEPART="item">
        <div class="sortie pa-2">
          <v-row justify="center" align="center">
            <v-col>
              <div
                v-if="useTextField[item['CH_DESIGNATION'] + 'CLD_QTE_DEPART'] == '' || useTextField[item['CH_DESIGNATION'] + 'CLD_QTE_DEPART'] == undefined || useTextField[item['CH_DESIGNATION'] + 'CLD_QTE_DEPART'] == false">
                <div v-if="isMobile">
                  <select v-if="!sortieDisabled" class="pa-2" v-model="item['CLD_QTE_DEPART']"
                    @change="selectChange(item['CH_DESIGNATION'], 'CLD_QTE_DEPART', item['CLD_QTE_DEPART'])"
                    :disabled="sortieDisabled"
                    style="border:solid 1px lightgrey;width:100%;text-align:center;background-color: white;"
                    align="center" :multiple="multipleSelection">
                    <!-- <option disabled value="">Séléctionner un état</option> -->
                    <option :disabled="sortieDisabled" :selected="elem == item['CLD_QTE_DEPART']"
                      v-for="(elem) in item['CLD_SELECTION'] == '' || item['CLD_SELECTION'] == undefined ? defaultFormValues : item['CLD_SELECTION'].split(',')"
                      :key="elem + 'sortie'" :value="elem"> {{ elem }} </option>
                  </select>
                  <div v-else align="center"
                    :style="!sortieDisabled ? 'background-color: white' : 'background-color:rgb(255,255,255,0.3);  color: rgb(0,0,0,0.4);'"
                    class="pa-2" style="border:solid 1px lightgray">
                    <div v-if="item['CLD_QTE_DEPART'] && typeof item['CLD_QTE_DEPART'] == 'object'">
                      {{ JSON.parse(JSON.stringify(item['CLD_QTE_DEPART'])).join(' , ') }}
                    </div>
                    <div v-else>
                      {{ item['CLD_QTE_DEPART'] }}
                    </div>
                  </div>
                </div>
                <div v-else
                  @click="sortieDisabled == false ? openFormValueDialog[item['CH_DESIGNATION'] + 'CLD_QTE_DEPART'] = true : undefined">
                  <div align="center" class="pa-2" style="cursor: pointer;border: solid 1px lightgrey;"
                    :style="!sortieDisabled ? 'background-color: white' : 'background-color:rgb(255,255,255,0.3);  color: rgb(0,0,0,0.4);'">
                    <div v-if="item['CLD_QTE_DEPART'] == undefined || item['CLD_QTE_DEPART'] == ''">
                      Séléctionner un état
                    </div>
                    <div v-else>
                      <div v-if="item['CLD_QTE_DEPART'] && typeof item['CLD_QTE_DEPART'] == 'object'">
                        {{ JSON.parse(JSON.stringify(item['CLD_QTE_DEPART'])).join(' , ') }}
                      </div>
                      <div v-else>
                        <div v-if="item['CLD_QTE_DEPART'] == ''">
                          Séléctionner un état
                        </div>
                        <div v-else>
                          {{ item['CLD_QTE_DEPART'] }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <v-dialog v-model="openFormValueDialog[item['CH_DESIGNATION'] + 'CLD_QTE_DEPART']" width="400">
                    <v-card height="390">
                      <v-col>
                        <div class="mb-3" align="center">
                          <h3>
                            Séléctionner un état
                          </h3>
                          <div style="color:grey;font-size: 12px;">
                            <div v-if="item['CLD_QTE_DEPART'] && typeof item['CLD_QTE_DEPART'] == 'object'">
                              ( {{ JSON.parse(JSON.stringify(item['CLD_QTE_DEPART'])).join(' , ') }} )
                            </div>
                            <div v-else>
                              ({{ item['CLD_QTE_DEPART'] }})
                            </div>
                          </div>
                        </div>
                        <select class="pa-2" v-model="item['CLD_QTE_DEPART']"
                          @change="selectChange(item['CH_DESIGNATION'], 'CLD_QTE_DEPART', item['CLD_QTE_DEPART'])"
                          style="border:solid 1px lightgrey;width:100%;text-align:center;background-color:white !important;height:270px;"
                          align="center" multiple>
                          <option :disabled="sortieDisabled"
                            style="border: solid 1px lightgrey;padding: 5px;margin: 3px 1px;"
                            v-for="(elem) in item['CLD_SELECTION'] == '' || item['CLD_SELECTION'] == undefined ? defaultFormValues : item['CLD_SELECTION'].split(',')"
                            :key="elem + 'sortie'" :value="elem"> {{ elem }} </option>
                        </select>
                        <div style="font-size: 12px;color: grey;" align="right" class="mt-2">
                          Maintenir la touche <span style="color:black;font-weight: bold;">CTRL</span> pour séléctionner
                          plusieur ligne
                        </div>
                      </v-col>
                    </v-card>
                  </v-dialog>
                </div>
              </div>
              <div v-else>
                <v-text-field v-model="item['CLD_QTE_DEPART']" variant="text" bg-color="white" hide-details :disabled="sortieDisabled"
                  class="centered-input sortie" density="compact" style="font-weight: bold;"
                  @update:modelValue="selectChange(item['CH_DESIGNATION'], 'CLD_QTE_DEPART', item['CLD_QTE_DEPART'])">
                </v-text-field>
              </div>
            </v-col>
            <v-col cols="auto" v-if="!sortieDisabled">
              <v-checkbox hide-details density="compact" v-model="useTextField[item['CH_DESIGNATION'] + 'CLD_QTE_DEPART']"
                @update:modelValue="changeTypeOption(item, 'CLD_QTE_DEPART')">
                <template v-slot:label>
                  <v-icon size="20" v-bind="props">mdi-pen</v-icon>
                </template>
              </v-checkbox>
            </v-col>
          </v-row>
        </div>
      </template>
      <template #item-CH_DESIGNATION="item">
        <div style="height:100%;" align="center">
          {{ item.CH_DESIGNATION }}
        </div>
      </template>
      <template #item-CLD_QTE_RETOUR="item">
        <div class="entrer pa-2">
          <v-row>
            <v-col>
              <div
                v-if="useTextField[item['CH_DESIGNATION'] + 'CLD_QTE_RETOUR'] == '' || useTextField[item['CH_DESIGNATION'] + 'CLD_QTE_RETOUR'] == undefined || useTextField[item['CH_DESIGNATION'] + 'CLD_QTE_RETOUR'] == false">
                <div v-if="isMobile">
                  <select v-if="!entrerDisabled" class="pa-2" v-model="item['CLD_QTE_RETOUR']"
                    @change="selectChange(item['CH_DESIGNATION'], 'CLD_QTE_RETOUR', item['CLD_QTE_RETOUR'])"
                    :disabled="entrerDisabled"
                    style="border:solid 1px lightgrey;width:100%;text-align:center;background-color:white;" align="center"
                    :multiple="multipleSelection">
                    <!-- <option disabled value="">Séléctionner un état</option> -->
                    <option :disabled="entrerDisabled" :selected="elem == item['CLD_QTE_RETOUR']"
                      v-for="(elem) in item['CLD_SELECTION'] == '' || item['CLD_SELECTION'] == undefined ? defaultFormValues : item['CLD_SELECTION'].split(',')"
                      :key="elem + 'entrer'" :value="elem"> {{ elem }} </option>
                  </select>
                  <div v-else align="center"
                    :style="!entrerDisabled ? 'background-color: white' : 'background-color:rgb(255,255,255,0.3);  color: rgb(0,0,0,0.4);'"
                    class="pa-2" style="border:solid 1px lightgray">
                    <div v-if="item['CLD_QTE_RETOUR'] && typeof item['CLD_QTE_RETOUR'] == 'object'">
                      {{ JSON.parse(JSON.stringify(item['CLD_QTE_RETOUR'])).join(' , ') }}
                    </div>
                    <div v-else>
                      <div v-if="item['CLD_QTE_RETOUR'] == ''">
                        Séléctionner un état
                      </div>
                      <div v-else>
                        {{ item['CLD_QTE_RETOUR'] }}
                      </div>
                    </div>
                  </div>
                </div>
                <div v-else
                  @click="entrerDisabled == false ? openFormValueDialog[item['CH_DESIGNATION'] + 'CLD_QTE_RETOUR'] = true : undefined">
                  <div align="center" class="pa-2" style="cursor: pointer;border: solid 1px lightgrey;"
                    :style="!entrerDisabled ? 'background-color: white' : 'background-color:rgb(255,255,255,0.3);  color: rgb(0,0,0,0.4);'">
                    <div v-if="item['CLD_QTE_RETOUR'] == undefined || item['CLD_QTE_RETOUR'] == ''">
                      Séléctionner un état
                    </div>
                    <div v-else>
                      <div v-if="item['CLD_QTE_RETOUR'] && typeof item['CLD_QTE_RETOUR'] == 'object'">
                        {{ JSON.parse(JSON.stringify(item['CLD_QTE_RETOUR'])).join(' , ') }}
                      </div>
                      <div v-else>
                        {{ item['CLD_QTE_RETOUR'] }}
                      </div>
                    </div>
                  </div>
                  <v-dialog v-model="openFormValueDialog[item['CH_DESIGNATION'] + 'CLD_QTE_RETOUR']" width="400">
                    <v-card height="390">
                      <v-col>
                        <div class="mb-3" align="center">
                          <h3>
                            Séléctionner un état
                          </h3>
                          <div style="color:grey;font-size: 12px;">
                            <div v-if="item['CLD_QTE_RETOUR'] && typeof item['CLD_QTE_RETOUR'] == 'object'">
                              ( {{ JSON.parse(JSON.stringify(item['CLD_QTE_RETOUR'])).join(' , ') }} )
                            </div>
                            <div v-else>
                              ({{ item['CLD_QTE_RETOUR'] }})
                            </div>
                          </div>
                        </div>
                        <select class="pa-2" v-model="item['CLD_QTE_RETOUR']"
                          @change="selectChange(item['CH_DESIGNATION'], 'CLD_QTE_RETOUR', item['CLD_QTE_RETOUR'])"
                          style="border:solid 1px lightgrey;width:100%;text-align:center;background-color:white;height:270px;"
                          align="center" multiple>
                          <option :disabled="entrerDisabled"
                            style="border: solid 1px lightgrey;padding: 5px;margin: 3px 1px;"
                            v-for="(elem) in item['CLD_SELECTION'] == '' || item['CLD_SELECTION'] == undefined ? defaultFormValues : item['CLD_SELECTION'].split(',')"
                            :key="elem + 'sortie'" :value="elem"> {{ elem }} </option>
                        </select>
                        <div style="font-size: 12px;color: grey;" align="right" class="mt-2">
                          Maintenir la touche <span style="color:black;font-weight: bold;">CTRL</span> pour séléctionner
                          plusieur ligne
                        </div>
                      </v-col>
                    </v-card>
                  </v-dialog>
                </div>
              </div>
              <div v-else>
                <v-text-field v-model="item['CLD_QTE_RETOUR']" variant="text" hide-details bg-color="white" :disabled="entrerDisabled"
                  class="centered-input entrer" density="compact" style="font-weight: bold;"
                  @update:modelValue="selectChange(item['CH_DESIGNATION'], 'CLD_QTE_RETOUR', item['CLD_QTE_RETOUR'])">
                </v-text-field>
              </div>
            </v-col>
            <v-col cols="auto" v-if="!entrerDisabled">
              <v-checkbox hide-details density="compact" v-model="useTextField[item['CH_DESIGNATION'] + 'CLD_QTE_RETOUR']"
                @update:modelValue="changeTypeOption(item, 'CLD_QTE_RETOUR')">
                <template v-slot:label>
                  <v-icon size="20" v-bind="props">mdi-pen</v-icon>
                </template>
              </v-checkbox>
            </v-col>
          </v-row>
        </div>

      </template>
    </Vue3EasyDataTable>
  </v-col>
</template>

<script>

import Vue3EasyDataTable from "vue3-easy-data-table";

export default {
  data() {
    return {
      useTextField: {},
      openFormValueDialog: {}
    }
  },
  mounted() {
    this.setForm()
  },
  computed: {
    isMobile() {
      return this.$vuetify.display.smAndDown
    }
  },
  watch: {
    formData() {
      this.setForm()
    }
  },
  methods: {
    setForm() {
      if (this.formData.CLD_CHECK_LIST_JSON_GESTION && this.formData.CLD_CHECK_LIST_JSON_GESTION.length != 0) {
        let tmpData = this.formData.CLD_CHECK_LIST_JSON_GESTION
        console.log("tmpData", tmpData);
        
        for (let i = 0; i < tmpData.length; i++) {
          let elem = tmpData[i]
          let defaultForm = this.defaultFormValues

          if (elem['CLD_SELECTION'] && elem['CLD_SELECTION'].length != 0)
            defaultForm = elem['CLD_SELECTION'].split(',')

          this.useTextField[elem['CH_DESIGNATION'] + 'CLD_QTE_DEPART'] = false
          this.useTextField[elem['CH_DESIGNATION'] + 'CLD_QTE_RETOUR'] = false
          if (elem.CLD_QTE_DEPART != "" && this.arrayItemsInArray(elem.CLD_QTE_DEPART, defaultForm)) {
            this.useTextField[elem['CH_DESIGNATION'] + 'CLD_QTE_DEPART'] = true
            this.changeTypeOption(elem, 'CLD_QTE_DEPART')
          }
          if (elem.CLD_QTE_RETOUR != "" && this.arrayItemsInArray(elem.CLD_QTE_RETOUR, defaultForm)) {
            this.useTextField[elem['CH_DESIGNATION'] + 'CLD_QTE_RETOUR'] = true
            this.changeTypeOption(elem, 'CLD_QTE_RETOUR')
          }
        }
      }
    },
    arrayItemsInArray(itemsTab, array) {
      if (typeof itemsTab == 'object') {
        for (let i = 0; i < itemsTab.length; i++) {
          if (array.includes(itemsTab[i]))
            return false
        }
      } else {
        if (array.includes(itemsTab))
          return false
      }
      return true
    },
    changeTypeOption(item, key) {
      if (this.useTextField[item['CH_DESIGNATION'] + key] == false) {
        item[key] = item['CLD_SELECTION'] == '' || item['CLD_SELECTION'] == undefined ? this.defaultFormValues[0] : item['CLD_SELECTION'].split(',')[0]
        this.selectChange(item['CH_DESIGNATION'], key, item[key])
      }
    }
  },
  props: {
    get_tab_header: {},
    formData: {},
    defaultFormValues: {},
    entrerDisabled: {},
    sortieDisabled: {},
    selectChange: {},
    multipleSelection: {}
  },
  components: {
    Vue3EasyDataTable
  }
}
</script>
