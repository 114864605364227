<template>
  <div>
    <!-- <div style="background-color: red;position: absolute;z-index: 7 !important; right:21px; width:38px;height:35px">
        </div> -->
    <v-dialog v-model="closeVerifIsOpen" width="auto">
      <v-card>
        <v-col align="center">
          <h4 style="color:red">
            L'ensemble des modifications non enregistrer seront définitivement perdu.
          </h4>
          <h2 class="my-2">
            Fermer l'état des lieux ?
          </h2>
          <v-row>
            <v-col>
              <v-btn color="#E57373" style="color:white;font-weight: bold;" class="mr-1" @click="finalClose">
                Oui
              </v-btn>
              <v-btn color="#66BB6A" style="color:white;font-weight: bold;" class="ml-1"
                @click="closeVerifIsOpen = false">
                Non
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-card>
    </v-dialog>
    <v-dialog v-model="isAskSignatureOpen" width="100%" max-width="600">
      <v-col>
        <v-card class="pa-2" align=center>
          <v-row>
            <v-col align="left">
              <v-card-title>
                Envoyer un lien de signature par mail
              </v-card-title>
            </v-col>
            <v-col align="right">
              <v-btn @click="isAskSignatureOpen = false" color="red" density="compact" icon="mdi-close" variant="text"
                class="ma-2">
              </v-btn>
            </v-col>
          </v-row>
          <v-col align="center">
            <!-- <v-select class="mb-2" v-model="email_pdf" :items="emailList" variant="outlined"
                              :label="'Liste des emails prédéfini'" :no-data-text="'Pas de données disponibles'" hide-details>
                            </v-select> -->
            <v-text-field dense hide-details variant="outlined" :label="'Email destinataire'" v-model="email_pdf">
            </v-text-field>
            <v-col align=center>
              <v-btn @click="addEmailToListe">
                Ajouter
              </v-btn>
            </v-col>
            <v-col>
              <v-card class="pa-2 my-2" style="color:white;text-transform:uppercase;font-weight:normal;font-size:12px"
                color="red" variant=flat>
                Uniquement les photos préchargées seront envoyées en pièce jointe.
              </v-card>
              <h3 align=center class="mb-2">
                Liste des emails de destinataire ({{ emailSignatureListe.length }})
              </h3>
              <v-row>
                <v-col v-for="(email, idx) in emailSignatureListe" :key="idx" cols="auto">
                  <v-chip @click="deleteEmailToListe(idx)">
                    {{ email }}
                  </v-chip>
                </v-col>
              </v-row>
            </v-col>
            <v-menu open-on-hover open-delay="0" close-delay="0" v-if="emailList && emailList.length != 0">
              <template v-slot:activator="{ props }">
                <v-btn color="primary" v-bind="props" class="mt-2" density="compact" variant="tonal">
                  Email prédéfini
                </v-btn>
              </template>
              <v-list>
                <v-list-item v-for="(item, index) in emailList" :key="index" :value="index">
                  <v-list-item-title @click="email_pdf = item">{{ item }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
          <v-btn :disabled="emailSignatureListe.length == 0" color="#5C6BC0" @click="send_pdf(true)" class="mt-2"
            style="color:white">
            Envoyer
          </v-btn>
        </v-card>
      </v-col>
    </v-dialog>
    <v-dialog v-model="isSignatureOpen" width="600px">
      <v-card tile>
        <signature-pad v-if="selectedSignature" id="signature" :ref="selectedSignature" width="350px" :options="{
          onBegin: () => {
            $refs[selectedSignature].resizeCanvas();
          },
        }" />
        <v-card color="#eeeeee" tile>
          <v-col align="right">
            <v-btn text elevation="0" color="green" variant="tonal" class="mx-2" id="hideInPrint"
              @click="(isSignatureOpen = false), save(selectedSignature)">
              <v-icon>mdi-content-save</v-icon>
            </v-btn>
            <v-btn color="blue" class="mx-2" id="hideInPrint" variant="tonal" @click="undo(selectedSignature)"
              align="right">
              <v-icon>mdi-undo</v-icon>
            </v-btn>
            <v-btn color="red" variant="tonal" class="mx-2" id="hideInPrint" @click="clear(selectedSignature)">
              <v-icon>mdi-trash-can</v-icon>
            </v-btn>
          </v-col>
        </v-card>
      </v-card>
    </v-dialog>
    <v-dialog v-model="sendEmail" align="center" persistent>
      <div style="width:100vw" align="center">
        <v-card width="300">
          <v-row>
            <v-col>
              <v-card-title>
                Envoyer par Email
              </v-card-title>
            </v-col>
            <v-col align="right">
              <v-btn @click="sendEmail = false" color="red" density="compact" icon="mdi-close" variant="text"
                class="ma-2">
              </v-btn>
            </v-col>
          </v-row>
          <v-col align="center">
            <!-- <v-select class="mb-2" v-model="email_pdf" :items="emailList" variant="outlined"
                              :label="'Liste des emails prédéfini'" :no-data-text="'Pas de données disponibles'" hide-details>
                            </v-select> -->
            <v-text-field dense hide-details variant="outlined" :label="'Email destinataire'" v-model="email_pdf">
            </v-text-field>
            <v-menu open-on-hover open-delay="0" close-delay="0" v-if="emailList && emailList.length != 0">
              <template v-slot:activator="{ props }">
                <v-btn color="primary" v-bind="props" class="mt-2" density="compact" variant="tonal">
                  Email prédéfini
                </v-btn>
              </template>
              <v-list>
                <v-list-item v-for="(item, index) in emailList" :key="index" :value="index">
                  <v-list-item-title @click="email_pdf = item">{{ item }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-card class="pa-2 mt-2" style="color:white;text-transform:uppercase;font-weight:normal;font-size:12px"
              color="red">
              Uniquement les photos préchargées seront envoyées en pièce jointe.
            </v-card>
            <v-btn color="primary" @click="send_pdf(false)" class="mt-2" style="width: 100%">
              Envoyer
            </v-btn>
          </v-col>
        </v-card>
      </div>
    </v-dialog>
    <v-dialog v-model="modalSetPhoto2">
      <v-card>
        <v-col>
          <h3>
            "Aucune photos ajouter pour le retour du materiel. Voulez vous utiliser les photos de la sortie du
            materiel ?"
          </h3>
          <v-col>
            <v-row>
              <v-col align="center" cols="6">
                <v-btn @click="setPhoto1InPhoto2">
                  Oui </v-btn>
              </v-col>
              <v-col align="center" cols="6">
                <v-btn @click="modalSetPhoto2 = false">
                  Non </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-col>
      </v-card>
    </v-dialog>
    <v-dialog v-model="photoViewer" max-width="2000px" width="90%">
      <v-card color="transparent">
        <div
          v-if="formData[photoViewerDataKey] && formData[photoViewerDataKey].length !== 0 && nbrPhoto[photoViewerDataKey] != 0">
          <v-carousel height="100%" hide-delimiters v-model="carouselIndex">
            <v-carousel-item v-for="(item, i) in formData[photoViewerDataKey]" :key="i" :src="item"
              :style="isMobile ? 'width:90vw' : 'width:900px'">
              <!-- min-width="80vw"
                                  max-width="500px" -->
              <v-sheet color="transparent" height="100%" align="right">
                <v-row>
                  <v-col cols="auto" class="ma-2">
                    <v-card class="pa-2" variant="outlined" style="color:black;background-color: white;">
                      Date de la photo : {{
                        formatDate(photo_upload_data[photoViewerDataKey][i].server_modified, 'DD/MM/YYYY HH:mm')
                      }}
                    </v-card>
                  </v-col>
                  <v-col align="right" cols="" class="px-0">
                    <v-btn v-if="(selectedPhotoTypeByClass === 'sortie' &&
                      sortieDisabled === false) ||
                      (selectedPhotoTypeByClass === 'entrer' &&
                        entrerDisabled === false ||
                        (changeBackground === true ||
                          showEditableBtn === false))
                    " class="ma-2" color="red" variant="text" @click="deletePic(i, photoViewerDataKey),
                      (carouselIndex = carouselIndex - 1)
                      ">
                      <v-icon :size="35" color="red"> mdi-delete </v-icon>
                    </v-btn>
                  </v-col>
                  <v-col cols="auto" align="right" class="pl-0">
                    <v-btn fab variant="text" small class="ma-2" style="float: right">
                      <v-icon :size="40" color="red" @click="photoViewer = false">
                        mdi-close-circle-outline
                      </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-sheet>
            </v-carousel-item>
          </v-carousel>
        </div>
        <div v-else>
          <v-card>
            <v-col>
              Aucune photo enregistrée </v-col>
          </v-card>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog v-model="rootModal" width="500px">
      <v-card tile>
        <v-col align="center">
          <div style="font-size: 20px" class="pa-2">
            "Mode édition"
            ( vérification )
          </div>
          <v-text-field v-model="rootPassword" :label="'form.user_password'" outlined hide-details type="password">
          </v-text-field>
          <div v-if="rootErrorMessage" style="color: red" class="ma-1">
            {{ rootErrorMessage }}
          </div>
          <v-btn @click="formIsEditable(rootPassword)" class="mt-2">Valider</v-btn>
        </v-col>
      </v-card>
    </v-dialog>
    <div v-if="showAlert" id="hideInPrint"
      style="position: fixed;bottom: 0px; z-index: 5;width:100vw;background-color: red" align=center>
      <v-card color="red" class="pa-2">
        <div style="font-weight: bold;" align="center">
          {{ errorMsg }}
        </div>
      </v-card>
    </div>
    <div v-if="isLoading == false">
      <div v-if="isPhotoLoading == true" style="
                                                                position: fixed;
                                                                backdrop-filter: blur(15px);
                                                                background-color: rgb(0, 0, 0, 0.5);
                                                                height: 100%;
                                                                width: 100vw;
                                                                z-index: 5;
                                                                top: 0px;
                                                                left: 0px;
                                                              ">
        <v-col align="center" style="margin-top: 35vh">
          <v-progress-circular size="170" style="padding: 20px" indeterminate color="white">Chargement des photos
            {{ nbrPhoto[photoViewerDataKey] == undefined ? 0 : nbrPhoto[photoViewerDataKey] }} /
            {{ nbrPhotoFinded[photoViewerDataKey] }}
          </v-progress-circular>
        </v-col>
      </div>
      <div style="font-weight: bold;" class="etat-des-lieux-materiel" :style="{
        'background-color': changeBackground ? '#455A64' : 'white',
        'border-radius': '0px',
      }">
        <v-col cols="12" align="right" id="hideInPrint" class="px-0 pt-1 pr-3">
          <!-- <v-btn v-if="isAdmin && showEditableBtn && !changeBackground" variant="plain" @click="formIsEditable()">
          <v-icon size="25px">mdi-file-document-edit </v-icon>
        </v-btn> -->
          <v-row style="position:fixed;width:100%;z-index:3">
            <v-col cols="" class="pr-1 mt-1 ">
              <v-btn rounded density="compact" icon=mdi-close @click="closeDialog()" color="red" size=28>
              </v-btn>
            </v-col>
            <v-col cols="auto" class="pl-0 mt-1">
              <WifiState :getLvl="getLvl"></WifiState>
            </v-col>
          </v-row>
        </v-col>
        <div v-if="changeBackground" align="center" style="padding-top: 20px">
          <v-chip>
            <h3>
              "Mode édition"
            </h3>
          </v-chip>
        </div>
        <v-col v-if="isPrinting == false">
          <div id="captureMateriel" class="pa-3" style="background-color: transparent; border-radius: 5px">
            <v-row>
              <v-col cols="" align="left" class="py-0 px-2">
                <v-col>
                  <v-row align=center>
                    <v-col cols="12" id="info" style="display: none" class="py-0">
                      {{ societe["RS"] }}<br />
                      {{ agency["ag_adresse"] }} <br />
                      {{ agency["ag_cp"] }}
                      {{ agency["ag_ville"] }} <br />
                      {{ agency["ag_telephone"] }} <br />
                      {{ agency["ag_courriel"] }} <br />
                      {{ agency["ag_siteweb"] }} <br />
                    </v-col>
                    <v-col class="pt-0" cols="">
                      <div align="center">
                        <h1 class="pt-0 mt-0" style="text-transform: uppercase">
                          <!-- {{ data.title }} -->
                          ETAT DES LIEUX MATERIEL
                        </h1>
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
                <v-card v-if="mainClasse != 'termine' && mainClasse != 'entrer'" id="hideInPrint" flat
                  :class="[mainClasse, 'outlined']">
                  <v-col>
                    Couleur des éléments modifiables </v-col>
                </v-card>
                <v-card v-else id="hideInPrint" flat>
                  <v-col class="py-2" v-if="sortieDisabled != entrerDisabled &&
                    changeBackground == false
                  " :class="[mainClasse, 'outlined']">
                    <div v-if="sortieDisabled != entrerDisabled">
                      Couleur des éléments modifiables </div>
                  </v-col>
                </v-card>
              </v-col>
            </v-row>
            <v-col class="pt-1 px-0" id="formContainer">
              <div>
                <v-divider class="mt-5" color="lightgrey"></v-divider>
                <v-card-title class="pl-0" style="text-transform: uppercase; font-weight: bold">
                  Informations général
                </v-card-title>
                <v-col class="pa-0">
                  <v-row>
                    <v-col md="3" cols="12" sm="4" class="pa-1">
                      <div style="background-color: transparent" class="rounded px-1">
                        <v-col class="px-0 py-0">
                          <div style="background-color: transparent" class="py-1 rounded">
                            Nom Client <v-card style="
                            background-color: white;
                            border: solid 1px black;
                          " outlined class="pa-2" flat>
                              {{ formData["CLD_CLIENT_RAISON_SOCIALE"] }}
                            </v-card>
                          </div>
                        </v-col>
                      </div>
                    </v-col>
                    <v-col v-for="information in getInformation.data" :key="information.key" md="3" cols="12" sm="4"
                      class="pa-1">
                      <div style="background-color: transparent" class="pa-1 rounded"
                        v-if="information.key !== 'CLD_DESCRIPTION'">
                        {{ information.label }}
                        <v-card style="background-color: white; border: solid 1px black" outlined class="pa-2" flat>
                          <!-- --{{formData[information.key]}}-- -->
                          <div v-if="information.key != 'CLD_AGENCY'">
                            <div v-if="formData[information.key].length > 0">
                              {{ formData[information.key] }}
                            </div>
                            <div v-else style="height:24px">

                            </div>
                          </div>
                          <!-- <div v-else-if="formData[information.key] &&
                          formData[information.key]['ag_nom']
                        "> -->
                          <div v-else-if="agency && agency.ag_nom">
                            {{ agency.ag_nom }}
                          </div>
                        </v-card>
                      </div>
                    </v-col>
                    <v-col md="3" cols="12" sm="4" class="pa-1">
                      <div style="background-color: transparent" class="pa-1 rounded">
                        Adresse
                        <v-card style="background-color: white; border: solid 1px black" outlined flat>
                          <v-row class="pa-5">
                            <v-col cols="auto" class="pa-0 pr-1">
                              {{
                                formData["CLD_CLIENT_ADRESSE"] +
                                " " +
                                formData["CLD_CLIENT_VILLE"] +
                                " " +
                                formData["CLD_CLIENT_CODEPOSTAL"] +
                                formData["CLD_CLIENT_COMP_ADRESSE"]
                              }}
                            </v-col>
                            <v-col cols="auto" class="pa-0"
                              v-if="formData['CLD_NOM_CLIENT'] && formData['CLD_NOM_CLIENT'].length > 0">
                              {{
                                " - " +
                                formData["CLD_NOM_CLIENT"]
                              }}
                            </v-col>
                          </v-row>
                        </v-card>
                      </div>
                    </v-col>
                    <!-- v-if="formData['CLD_FORMATION'] == 'oui'" -->
                    <v-col v-if="formData['CLD_FORMATION'] == 'oui'" cols="12" class="px-1"> <v-card variant="flat"
                        class="pa-2" color="red" style="color:white" align="center">Formation nécessaire</v-card>
                    </v-col>
                  </v-row>
                </v-col>
                <v-divider class="mt-5" color="lightgrey"></v-divider>
                <v-card-title class="pl-0 mb-2" style="text-transform: uppercase; font-weight: bold">Informations sur le
                  materiel</v-card-title>
                <v-col class="pa-0">
                  <v-row>
                    <v-col md="3" cols="12" sm="4" class="pa-1">
                      <div style="background-color: transparent" class="pa-1 rounded">
                        {{ getInformation.description.label }}
                        <v-card style="background-color: white; border: solid 1px black" outlined class="pa-2" flat>
                          {{ formData[getInformation.description.key] }}
                        </v-card>
                      </div>
                    </v-col>
                    <v-col
                      v-for="(info, key) in Object.fromEntries(Object.entries(parc_information).filter(([key, value]) => value !== null && value !== '' && park[key]))"
                      :key="key" md="3" cols="12" sm="4" class="pa-1">
                      <div style="background-color: transparent" class="pa-1 rounded">
                        {{ park[key] }}
                        <v-card style="background-color: white; border: solid 1px black" outlined class="pa-2" flat>
                          {{ info }}
                        </v-card>
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
                <div v-if="formData && formData['CLD_OR_INFO'] && formData['CLD_OR_INFO'].length > 0">
                  <v-divider class="mt-5" color="lightgrey"></v-divider>
                  <v-card-title class="pl-0 mb-2" style="text-transform: uppercase; font-weight: bold">Informations de
                    préparation</v-card-title>
                  <v-row>
                    <v-col v-for="(info, key) in JSON.parse(formData['CLD_OR_INFO'])" :key="key" md="3" cols="12" sm="4"
                      class="pa-1">
                      <div style="background-color: transparent" class="pa-1 rounded">
                        {{ key }}
                        <v-card style="background-color: white; border: solid 1px black" outlined class="pa-2" flat>
                          {{ info }}
                        </v-card>
                      </div>
                    </v-col>
                  </v-row>
                </div>
                <div v-if="formData['CLD_EQUIPEMENTS'] && formData['CLD_EQUIPEMENTS'].equipement">
                  <v-divider class="mt-5" color="lightgrey"></v-divider>
                  <v-card-title class="pl-0" style="text-transform: uppercase; font-weight: bold">
                    Equipements </v-card-title>
                  <v-col class="px-1 py-0">
                    <!-- {{ parcListe[0] }} -->
                    <v-row>
                      <v-col class="px-1 rounded" v-if="getparcListeMatricul && getparcListeMatricul.length > 0"
                        cols="12">
                        <v-text-field v-if="!sortieDisabled" label="Rechercher un équipement valide (3 lettres minimum)"
                          style="" clearable class="mb-3 rounded" v-model="equipementSearch" hide-details
                          variant="outlined" bg-color="white">
                        </v-text-field>
                        <v-card variant="outlined" v-if="equipementSearch && equipementSearch.length > 2" class="pa-2"
                          style="background-color:#FFF9C4 ">
                          <v-col>
                            Liste des équipements <span style="color:red;font-weight: bold">valides</span> :
                          </v-col>
                          <v-divider class="mb-3"></v-divider>
                          <v-col>
                            <v-row>
                              <v-col class="pa-1" cols="auto"
                                v-for="(elem, idx) in getparcListeMatricul.filter((val) => val.value.toUpperCase().includes(equipementSearch.toUpperCase()))"
                                :key="idx">
                                <v-chip>
                                  {{ elem.value }}
                                </v-chip>
                              </v-col>
                              <v-col
                                v-if="getparcListeMatricul.filter((val) => val.value.toUpperCase().includes(equipementSearch.toUpperCase())).length == 0"
                                align="center">
                                Aucun équipement ne correspond à votre recherche
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-card>
                      </v-col>
                      <v-col v-for="(value, idx) in formData['CLD_EQUIPEMENTS'].equipement" :key="idx" class="px-1">
                        <v-card variant="flat" class="pa-2 mb-1 sortie">
                          <v-chip v-if="getparcListeMatricul.find((val) => val.value == value.matricule) == undefined"
                            style="font-weight:bold;background-color:red;color:white;" class="mb-2">
                            Équipement inconnu
                          </v-chip>
                          <v-text-field bg-color=white :readonly="sortieDisabled" hide-details
                            :label="value.description" v-model="value.matricule"></v-text-field>
                          <!-- <v-autocomplete v-else bg-color=white :label="value.description" :readonly="sortieDisabled"
                            :items="getparcListeMatricul.filter((val2) => val2.family == getparcListeMatricul.find((val) => val.value == value.matricule).family)"
                            hide-details v-model="value.matricule" auto-select-first></v-autocomplete> -->
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-col>
                </div>
                <v-divider class="mt-3" color="lightgrey"></v-divider>
                <v-card-title class="pl-0" style="text-transform: uppercase; font-weight: bold">{{
                  "Départ / Retour"
                }}</v-card-title>
                <v-col class="px-1">
                  <v-row>
                    <v-col v-for="elem in getDates" :key="elem.key" cols="12" sm="6" class="pa-1">
                      <div :class="elem.class" class="outlined" align="center">
                        {{ elem.label }}
                        <v-divider style="width: 100%" class="mb-2 mt-0" color="lightgrey"></v-divider>
                        <div class="pa-2 pt-1">
                          <v-btn v-if="!elem.disabled" variant="text" class="outlined"
                            @click="datemodal[elem.key] = true" style="
                                                                                    width: 100%;
                                                                                    background-color: white;
                                                                                    font-weight: bold;
                                                                                  " :disabled="elem.disabled">
                            {{ formatDate(formData[elem.key], "DD/MM/YYYY") }}
                          </v-btn>
                          <v-card v-else variant="text" class="outlined" style="
                                                                                    width: 100%;
                                                                                    background-color: white;
                                                                                    height: 36px;
                                                                                    font-weight: bold;
                                                                                  ">
                            <div v-if="(!elem.disabled || data.disabled.sortie) &&
                              !formData[elem.key] == ''
                            ">
                              {{ formatDate(formData[elem.key], "DD/MM/YYYY") }}
                            </div>
                          </v-card>
                        </div>
                        <v-dialog v-model="datemodal[elem.key]">
                          <Datepicker class="etat-des-lieux-materiel" keepActionRow locale="fr" type="date"
                            :clearable="false" :cancelText="'Fermer'" :selectText="'Valider'" inline
                            @update:modelValue="datemodal[elem['key']] = false" v-model="formData[elem['key']]">
                          </Datepicker>
                        </v-dialog>
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
                <div
                  v-if="this.formData['CLD_COMPLEMENT_STOCK'] && this.formData['CLD_COMPLEMENT_STOCK'].length != 0 && this.formData['CLD_COMPLEMENT_STOCK'].length != 1">
                  <v-divider class="mt-2" color="lightgrey"></v-divider>
                  <v-card-title class="pl-0" style="text-transform: uppercase; font-weight: bold">
                    Complément </v-card-title>
                  <v-card variant="flat" style="border: solid 1px black" class="pa-2 mb-1">
                    {{ this.formData["CLD_COMPLEMENT_STOCK"] }}
                  </v-card>
                </div>
                <div
                  v-if="getHiddenComment.find((val) => this.formData[val.key] && this.formData[val.key].length > 0) || getComment.find((val) => this.formData[val.key] && this.formData[val.key].length > 0)">
                  <v-divider class="mt-2" color="lightgrey"></v-divider>
                  <v-card-title class="pl-0" style="text-transform: uppercase; font-weight: bold">
                    Commentaire(s)
                  </v-card-title>
                  <v-row id="hideInPrint" class=""
                    v-if="getHiddenComment.find((val) => this.formData[val.key] && this.formData[val.key].length > 0)">
                    <v-col v-for="(elem, i) in getHiddenComment" :key="i" class="px-2" cols="12">
                      <v-card variant="flat" style="" class="pa-2 mb-1" :class="elem.class">
                        <h4 align=center class="pl-0 pb-2" style="">
                          {{ elem.label }} </h4>
                        <v-card class="pa-2" variant="flat" style="min-height:40px;color:red">
                          {{ this.formData[elem.key] }}
                        </v-card>
                      </v-card>
                    </v-col>
                  </v-row>
                  <v-row class="mt-1"
                    v-if="getComment.find((val) => this.formData[val.key] && this.formData[val.key].length > 0)">
                    <v-col v-for="(elem, i) in getComment" :key="i" class="px-2" cols="12" md="6">
                      <v-card variant="flat" style="" class="pa-2 mb-1" :class="elem.class">
                        <h4 align=center class="pl-0 pb-2" style="">
                          {{ elem.label }} </h4>
                        <v-card class="pa-2" variant="flat" style="min-height:40px;color:red">
                          {{ this.formData[elem.key] }}
                        </v-card>
                      </v-card>
                    </v-col>
                  </v-row>
                  <v-divider class="my-2" color="lightgrey"></v-divider>
                </div>
                <form-options-table v-if="typeof formData.CLD_CHECK_LIST_JSON_GESTION == 'object'"
                  :get_tab_header="get_tab_header" :formData="formData" :defaultFormValues="defaultFormValues"
                  :entrerDisabled="entrerDisabled" :sortieDisabled="sortieDisabled" :selectChange="selectChange"
                  :multipleSelection="multipleSelection"></form-options-table>
                <v-divider class="mt-4" color="lightgrey"></v-divider>
                <v-card-title class="pl-0" style="text-transform: uppercase; font-weight: bold">
                  PHOTOS
                </v-card-title>
                <v-row class="px-2">
                  <v-col v-for="photo in getPhoto" :key="photo.key" sm="6" cols="12" class="pa-1">
                    <v-col :class="[photo.class, 'outlined']">
                      <v-row justify="center" style="position: relative">
                        <v-col cols="4" md="3" class="photo">
                          <v-file-input class="photo" v-if="photo.disabled" v-model="formData[photo.tmpKey]"
                            variant="outlined" :disabled="photo.disabled"
                            :prepend-icon="nbrPhoto[photo.key] != undefined || nbrPhotoFinded[photo.key] == undefined ? 'mdi-camera-off' : ''"
                            hide-input accept="image/png, image/jpeg" :label="photo.label" hide-details @click="uploadPhoto(photo, photo.key
                            )" @change="updatePhotos(
                              formData[photo.tmpKey],
                              photo.Key,
                              photo.tmpKey
                            )
                              ">
                          </v-file-input>
                          <v-file-input v-else class="photo" v-model="formData[photo.tmpKey]" variant="outlined"
                            :disabled="photo.disabled"
                            :prepend-icon="nbrPhoto[photo.key] != undefined || nbrPhotoFinded[photo.key] == undefined || nbrPhotoFinded[photo.key] == 0 ? 'mdi-camera' : ''"
                            hide-input @click="uploadPhoto(photo, photo.key
                            )" accept="image/png, image/jpeg" :label="photo.label" hide-details @change="updatePhotos(
                              formData[photo.tmpKey],
                              photo.key,
                              photo.tmpKey
                            )
                              ">
                          </v-file-input>
                        </v-col>
                        <v-col cols="8" md="9" class="px-0">
                          <v-btn height="50px" width="100%" style="background-color: white;text-transform: none;"
                            variant="outlined" class="form_card outlined" @click="openPhotoViewer(photo, photo.key)">
                            <div
                              v-if="nbrPhoto[photo.key] == undefined && (nbrPhotoFinded[photo.key] != undefined && nbrPhotoFinded[photo.key] != 0)">
                              {{ nbrPhotoFinded[photo.key] }} photo(s) en attente de chargement
                              <v-icon color="black"> mdi-cloud-download </v-icon>
                            </div>
                            <div v-else>
                              {{ nbrPhoto[photo.key] == undefined ? 0 : nbrPhoto[photo.key] }}
                              <v-icon color="black"> mdi-image </v-icon>
                            </div>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-col>
                </v-row>
                <v-divider class="mt-6" color="lightgrey"></v-divider>
                <v-card-title class="pl-0 mb-3" style="text-transform: uppercase; font-weight: bold">
                  Observations
                </v-card-title>
                <v-row class="mb-3 px-2">
                  <v-col v-for="observation in getObservations" :key="observation.key" sm="6" cols="12" class="pa-1">
                    <div :class="[observation.class, 'outlined']">
                      <div class="pa-2">
                        {{ observation.label }}
                      </div>
                      <v-col cols="12"
                        v-if="drawInObservation && imageExists('family/' + parc_information.FAMILLE + '.jpg')">
                        <v-row justify="center" align="center">
                          <v-col cols="auto" style="position:relative">
                            <v-img :src="'family/' + parc_information.FAMILLE + '.jpg'"
                              style="height:390px;width:350px;border:solid 1px grey"></v-img>
                            <signature-pad :scaleToDevicePixelRatio="false"
                              :id="'signaturePadObservation' + observation.class"
                              :ref="'signaturePadObservation' + observation.class"
                              style="position:absolute;top:12px;height:390px;width:350px"
                              :style="{ 'background-color': observation.disabled ? 'rgb(0,0,0,0)' : 'rgb(0,0,0,0.1)' }"
                              :options="{
                                penColor: !observation.disabled ? '#FFF176' : 'transparent',
                                minWidth: 2,
                                maxWidth: 2,
                              }"
                              @input="onInput('signaturePadObservation' + observation.class, !observation.disabled)" />
                          </v-col>
                          <v-col cols="12">
                            <v-row>
                              <v-col cols="6">
                                <v-btn :disabled="observation.disabled" color="blue" class="mx-2" id="hideInPrint"
                                  variant="tonal" @click="undo('signaturePadObservation' + observation.class)"
                                  align="right">
                                  <v-icon>mdi-undo</v-icon>
                                </v-btn>
                              </v-col>
                              <v-col cols="6" align="right">
                                <v-btn :disabled="observation.disabled" color="red" variant="tonal" class="mx-2"
                                  id="hideInPrint" @click="clear('signaturePadObservation' + observation.class)">
                                  <v-icon>mdi-trash-can</v-icon>
                                </v-btn>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-divider color="lightgrey"></v-divider>
                      <div class="pa-2">
                        <v-textarea class="" id="hideInPrint" style="background-color: white; font-weight: bold;"
                          :placeholder="observation.label + '...'" variant="text" v-model="formData[observation.key]"
                          no-resize min-height="135" hide-details :readonly="observation.disabled">
                        </v-textarea>

                        <v-card style="display:none;background-color: white;" variant="text" id="info"
                          class="outlined pa-2" min-height="135">
                          {{ formData[observation.key] }}
                        </v-card>
                      </div>
                    </div>
                  </v-col>
                </v-row>
                <v-divider class="mt-5" color="lightgrey"></v-divider>
                <v-card-title class="pl-0" style="text-transform: uppercase; font-weight: bold">
                  Informations complémentaire
                </v-card-title>

                <v-row class="px-2">
                  <v-col v-for="(elem, idx) in getComplementInfo" :key="idx" cols="12" sm="6" class="pa-1">
                    <div class="py-2 px-3" :class="[elem.class, 'outlined']" align="center" style="height: 100%">
                      {{ elem.label }}
                      <v-text-field variant="outlined" :readonly="elem.disabled" class="centered-input text--darken-3"
                        style="background-color: white; border: none" :class="['outlined']" v-model="formData[elem.key]"
                        hide-details single-line :type="elem.type" />
                    </div>
                  </v-col>
                </v-row>
              </div>
              <div id="hideInPrint">
                <v-divider v-if="showErrorInContrat && (mainClasse == 'termine' || mainClasse == 'entrer')" class="mt-5"
                  color="lightgrey"></v-divider>
                <v-col class="px-0" v-if="showErrorInContrat && (mainClasse == 'termine' || mainClasse == 'entrer')">
                  <v-card align="center" variant="flat" color="#E57373" style="color:white">
                    <v-row justify="center" align="center">
                      <v-col cols="auto" class="px-0">
                        <h2>Signaler un problème sur l'état des lieux</h2>
                      </v-col>
                      <v-col cols="auto" class="">
                        <v-checkbox style="font-size:25px" v-model="formData['CLD_ERREUR']"
                          :disabled="mainClasse == 'termine'" :label="formData['CLD_ERREUR'] == 1 ? 'Oui' : 'Non'"
                          hide-details></v-checkbox>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>
                <v-divider
                  :class="showErrorInContrat && (mainClasse == 'termine' || mainClasse == 'entrer') ? '' : 'mt-5'"
                  color="lightgrey"></v-divider>
              </div>
              <v-card-title class="pl-0" style="text-transform: uppercase; font-weight: bold">
                Signatures
              </v-card-title>
              <v-row class="pt-3 px-2">
                <v-col cols="12" sm="6" class="pa-1">
                  <div class="sortie outlined">
                    <v-card-title style="text-transform: uppercase">Signature de sortie</v-card-title>
                    <v-col>
                      <v-row>
                        <v-col cols="12" md="6" class="pt-0">
                          <v-card variant="flat" class="outlined">
                            <v-col align="center">
                              Contrôleur <v-text-field v-if="!sortieDisabled" style="font-weight: bold"
                                variant="outlined" :disabled="sortieDisabled"
                                v-model="formData[keyList.sortieControleur]" class="py-1" hide-details></v-text-field>
                              <v-text-field v-else readonly variant="outlined" style="font-weight: bold"
                                v-model="formData[keyList.sortieControleur]" class="py-1" hide-details></v-text-field>
                            </v-col>
                          </v-card>
                        </v-col>
                        <v-col cols="12" md="6" class="pt-0">
                          <v-card variant="flat" class="outlined">
                            <v-col align="center" v-if="isSignataireNotDisponible['depart'] == false">
                              Nom du signataire <v-text-field v-if="!sortieDisabled" dense variant="outlined"
                                style="font-weight: bold" :disabled="sortieDisabled"
                                v-model="formData[keyList.sortieClient]" class="py-1" hide-details></v-text-field>
                              <v-text-field v-else dense variant="outlined" style="font-weight: bold" readonly
                                v-model="formData[keyList.sortieClient]" class="py-1" hide-details></v-text-field>
                            </v-col>
                            <v-col v-if="isSignataireNotDisponible['depart'] == false" align="right" @click="writeSignature(
                              'CLD_SIGNATURE_DEPART',
                              sortieDisabled
                            )
                              ">
                              <v-img contain :src="formData['CLD_SIGNATURE_DEPART']" width="100%"
                                height="200px"></v-img>
                            </v-col>
                            <div id="hideInPrint">
                              <v-checkbox v-if="!sortieDisabled" label="Signataire facultatif" hide-details
                                v-model="isSignataireNotDisponible['depart']"></v-checkbox>
                            </div>
                          </v-card>
                        </v-col>
                        <v-col id="hideInPrint"
                          v-if="showSignByMail == 1 && (formData['CLD_CONTROLE_DEPART'] == 1 && formData['CLD_CONTROLE_RETOUR'] == 0) && (formData['CLD_SIGNATURE_DEPART'] == undefined || formData['CLD_SIGNATURE_DEPART'] == '')">
                          <v-card class="waiting pa-2" align=center style="color: white !important; width: 100%"
                            variant="flat" @click="askSignature()">
                            Envoyer pour signature en ligne du départ<v-icon class="ml-2"> mdi-email </v-icon>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-col>
                  </div>
                </v-col>
                <v-col cols="12" sm="6" class="pa-1">
                  <div class="entrer outlined">
                    <v-card-title style="text-transform: uppercase">
                      Signature de retour </v-card-title>
                    <v-col>
                      <v-row>
                        <v-col cols="12" md="6" class="pt-0">
                          <v-card variant="flat" class="outlined">
                            <v-col align="center">
                              Contrôleur <v-text-field v-if="!entrerDisabled" variant="outlined"
                                style="font-weight: bold" dense :disabled="entrerDisabled"
                                v-model="formData[keyList.returnControleur]" class="py-1" hide-details></v-text-field>
                              <v-text-field v-else variant="outlined" style="font-weight: bold" dense readonly
                                v-model="formData[keyList.returnControleur]" class="py-1" hide-details></v-text-field>
                            </v-col>
                          </v-card>
                        </v-col>
                        <v-col cols="12" md="6" class="pt-0">
                          <v-card variant="flat" class="outlined">
                            <v-col align="center" v-if="isSignataireNotDisponible['retour'] == false">
                              Nom du signataire <v-text-field v-if="!entrerDisabled" variant="outlined"
                                style="font-weight: bold" dense :disabled="entrerDisabled"
                                v-model="formData[keyList.returnClient]" class="py-1" hide-details></v-text-field>
                              <v-text-field v-else variant="outlined" style="font-weight: bold" readonly
                                v-model="formData[keyList.returnClient]" class="py-1" hide-details dense>
                              </v-text-field>
                            </v-col>
                            <v-col v-if="isSignataireNotDisponible['retour'] == false" align="right" @click="writeSignature(
                              'CLD_SIGNATURE_RETOUR',
                              entrerDisabled
                            )
                              ">
                              <v-img contain :src="formData['CLD_SIGNATURE_RETOUR']" width="100%"
                                height="200px"></v-img>
                            </v-col>
                            <div id="hideInPrint">
                              <v-checkbox v-if="!entrerDisabled" label="Signataire facultatif" hide-details
                                v-model="isSignataireNotDisponible['retour']"></v-checkbox>
                            </div>
                          </v-card>
                        </v-col>
                        <v-col id="hideInPrint"
                          v-if="showSignByMail == 1 && (formData['CLD_CONTROLE_DEPART'] == 1 && formData['CLD_CONTROLE_RETOUR'] == 1) && (formData['CLD_SIGNATURE_RETOUR'] == undefined || formData['CLD_SIGNATURE_RETOUR'] == '')">
                          <v-card class="waiting pa-2" align=center style="color: white !important; width: 100%"
                            variant="flat" @click="askSignature()">
                            Envoyer pour signature en ligne du retour <v-icon class="ml-2"> mdi-email </v-icon>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-col>
                  </div>
                </v-col>
                <v-col class="mr-4" id="hideInPrint" align="center">
                  <v-row v-if="!changeBackground" justify="center" class="mb-2">
                    <v-col align="center" cols="12" sm="6" md="4" class="pa-1">
                      <v-btn width="100%" :class="['mb-1', 'button']" color="#5C6BC0" style="color: white"
                        variant="flat" @click="downloadPdf()">
                        <v-icon class="mr-2"> mdi-download </v-icon>
                        Ouvrir en PDF </v-btn>
                    </v-col>
                    <v-col align="center" cols="12" sm="6" md="4" class="pa-1">
                      <v-btn width="100%" :class="['mb-1', 'button']" style="color: white" color="#A1887F"
                        variant="flat" @click="sharePdf()">
                        {{ "Envoyer par Email" }}
                        <v-icon class="ml-2"> mdi-email </v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row justify="center">
                    <v-col cols="12" sm="6" md="4" class="pa-1" v-if="changeBackground || mainClasse !== 'termine'">
                      <v-btn class="button" color="#43A047" style="color: white; width: 100%" variant="flat"
                        @click="logData()">
                        Finaliser </v-btn>
                    </v-col>
                    <!-- <v-col cols="12" sm="6" md="4" class="pa-1">
                    <v-btn v-if="changeBackground === false" class="button" color="#EF5350"
                      style="color: white; width: 100%" variant="flat" @click="closeDialog()">
                      Fermer
                    </v-btn>
                    <v-btn v-else class="button" style="
                      background-color: #e53935;
                      color: white;
                      width: 100%;
                    " text outlined @click="reLoadForm()">
                      Fermer
                    </v-btn>
                  </v-col> -->
                  </v-row>
                </v-col>
              </v-row>
            </v-col>

            <!-- <div v-if="showAlert" id="hideInPrint" color="red" style="
            position: fixed;
            z-index: 10;
            bottom: 20px;
            width: 100vw;
            left: 0px;
            right: 0px;
          " align="center">
              <v-card style="color: white" width="400px" color="red" class="pa-2">
                <div style="color: white">
                  {{ errorMsg }}
                </div>
              </v-card>
            </div> -->
            <div align="center" id="info" style="display: none">
              <v-divider class="mt-2" color="lightgrey"></v-divider>
              <v-col style="padding-bottom: 20px">
                {{ societe["RS"] }} - {{ societe["TypeEntreprise"] }} au capital
                de {{ societe["Capital"] }} € - SIRET
                {{ societe["NumeroSIRET"] }} - APE : {{ societe["CodeAPE"] }} -
                TVA : {{ societe["TVAIntracommunautaire"] }} <br />
                {{ agency["ag_nom"] }} - {{ agency["ag_adresse"] }}
                {{ agency["ag_cp"] }} {{ agency["ag_ville"] }} - Tél :
                {{ agency["ag_telephone"] }}
              </v-col>
            </div>
          </div>
        </v-col>
        <div v-else id="loading" class="text-center" style="
                                                                  position: absolute;
                                                                  top: 0px;
                                                                  padding-top: 40vh;
                                                                  padding-left: 40vw;
                                                                " align="center">
          <v-card class="pa-4">
            <div>
              Sauvegarde <span style="color:red">local</span> de votre ETADL.
            </div>
            <v-progress-circular class="mt-3" indeterminate size="100" :width="5" color="primary">
            </v-progress-circular>
          </v-card>
        </div>
        <v-snackbar v-model="snackbar.value" :timeout="snackbar.timeout" :color="snackbar.color"
          style="color:white;font-weight:bold" align="center">
          {{ snackbar.text }}
        </v-snackbar>
      </div>
    </div>
    <div v-else style="
        position: fixed;
        background-color: rgb(0, 0, 0, 0.5);
        height: 100%;
        width: 100vw;
        z-index: 5;
        top: 0px;
        left: 0px;
      ">
      <v-col align="center" style="margin-top: 35vh">
        <v-progress-circular size="120" indeterminate color="white">Chargement</v-progress-circular>
      </v-col>
    </div>
  </div>
</template>

<script>

import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import moment from "moment";
import engine from "../core/index";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { Browser } from "@capacitor/browser";
import { v4 as uuidv4 } from "uuid";
// import QrcodeVue from "qrcode.vue";
import SignaturePad from "vue3-signature-pad";
import FormOptionsTable from "./formOptionsTable"
import WifiState from "@/components/show_internet_state.vue"

// import SignaturePad from "@/signature-pad.vue";
// import Vue3EasyDataTable from "vue3-easy-data-table";

export default {
  data() {
    return {
      equipementSearch: "",
      isLoading: true,
      networkLvl: 0,
      closeVerifIsOpen: false,
      emailSignatureListe: [],
      isAskSignatureOpen: false,
      isSignataireNotDisponible: { 'retour': false, 'depart': false },
      // options_table_data: undefined,
      snackbar: {
        value: false,
        text: undefined,
        color: undefined,
        timeout: 10000
      },
      photo_upload_data: {},
      nbrPhotoFinded: {},
      show: false,
      home: {
        "filter_by_agency": "Filtre par agence",
        "filter": "Filtre",
        "finished": "Terminé",
        "in_progress": "En cours",
        "Agence::ag_nom": "Nom",
        "CLD_NOM_CLIENT": "Client",
        "CLD_ID_AGENCE": "Agence",
        "CLD_NUM_CONTRAT": "Contrat",
        "CLD_DATE_DEPART": "Début",
        "CLD_DATE_RETOUR": "Fin",
        "CLD_MATRICULE": "Matricule",
        "CLD_DESCRIPTION": "Description",
        "no_data_available": "Aucune donnée disponible",
        "CLD_KILOMETRE_DEPART": "Nombre de kilomètre en départ",
        "CLD_KILOMETRE_RETOUR": "Nombre de kilomètre en retour",
        "CLD_HORAMETRE_DEPART": "Compteur horaire de départ",
        "CLD_HORAMETRE_RETOUR": "Compteur horaire de retour",
        "CLD_QTE_DEPART": "Quantité de matériel départ",
        "CLD_QTE_RETOUR": "Quantité de matériel retour",
        "CLD_NIVEAU_ADBLUE_RETOUR": "Niveau ADBLUE de retour",
        "CLD_NIVEAU_ADBLUE_DEPART": "Niveau ADBLUE de départ",
        "CLD_NIVEAU_GAS_RETOUR": "Niveau carburant de retour",
        "CLD_NIVEAU_GAS_DEPART": "Niveau carburant de départ",
        "CLD_SIGNATURE_RETOUR": "Signature de retour",
        "CLD_SIGNATURE_DEPART": "Signature de sortie",
        "CLD_SIGNATAIRE_DEPART": "Signataire de départ",
        "CLD_SIGNATAIRE_RETOUR": "Signataire de retour",
        "CLD_CONTROLEUR_DEPART": "Contrôleur de retour",
        "CLD_CONTROLEUR_RETOUR": "Contrôleur de retour",
      },
      park: {
        "CREATION": "Création",
        "FAMILLE": "Famille",
        "MARQUE": "Marque",
        "MATRICULE": "Matricule",
        "MODIFICATION": "Modification",
        "NUMERO_SERIE": "Numéro de série",
        "SOUS_FAMILLE": "Sous famille",
        "TYPE": "Type",
      },
      varificationTab: undefined,
      carouselIndex: 0,
      isPhotoLoading: false,
      emailList: [],
      // logoSrc: "data:image/gif;base64,",
      agency: {},
      societe: {},
      personalizedData: {},
      qCodeSize: 150,
      rootErrorMessage: undefined,
      rootModal: false,
      rootPassword: "",
      selectedPhotoTypeByClass: undefined,
      showEditableBtn: false,
      nbrPhoto: {},
      mainClasse: undefined,
      infoMsg: undefined,
      isSignatureOpen: false,
      selectedSignature: undefined,
      email_pdf: undefined,
      sendEmail: false,
      pdfLink: "",
      modalSetPhoto2: false,
      isPrinting: false,
      options: {
        penColor: "black",
      },
      showAlert: false,
      formData: {},
      date: undefined,
      datemodal: {},
      photoViewer: false,
      photoViewerDataKey: undefined,
      selectedClient: {},
      sortieDisabled: true,
      entrerDisabled: true,
      errorMsg: undefined,
      // formaredClientList: [],
      parc_information: undefined,
      keyList: {
        parc: "CLD_PARC",
        sortieControleur: "CLD_CONTROLEUR_DEPART",
        returnControleur: "CLD_CONTROLEUR_RETOUR",

        sortieClient: "CLD_SIGNATAIRE_DEPART",
        sortieClientSignature: "CL_SIGNATURE_DEPART",
        returnClient: "CLD_SIGNATAIRE_RETOUR",
        returnClientSignature: "CL_SIGNATURE_RETOUR",
      },
    };
  },
  watch: {
    sendEmail() {
      this.reloadObservationPic()
    },
    isSignatureOpen(val) {
      if (val === false) this.selectedSignature = undefined;
    },
    // selectedClient(val) {
    //   this.formData["CL_NOM_CLIENT"] = val["CL_NOM_CLIENT"];
    //   this.formData["CLD_COMPLEMENT_STOCK"] = val["CLD_COMPLEMENT_STOCK"];
    //   this.formData["CL_CLIENT_RAISON_SOCIALE"] =
    //     val["CL_CLIENT_RAISON_SOCIALE"];
    //   this.formData["CL_CLIENT_EMAIL"] = "PAS_D_EMAIL";
    //   this.formData["CLD_LIEN"] = val["CL_LIEN"];
    //   this.formData["CL_ID_CLIENT"] = val["CL_ID_CLIENT"];
    //   this.getEmailList(val["CL_ID_CLIENT"]);
    // },
    showAlert(val) {
      let that = this;
      if (val === true) {
        setTimeout(function () {
          that.showAlert = false;
        }, 2000);
      }
    },
  },
  mounted() {
    this.formData = JSON.parse(JSON.stringify(this.data.data));
    let that = this;
    this.getPhotoFromDropBox();

    if (that.formData.CLD_CHECK_LIST_JSON != "")
      that.formData.CLD_CHECK_LIST_JSON = JSON.parse(that.formData.CLD_CHECK_LIST_JSON)
    else
      that.formData.CLD_CHECK_LIST_JSON = { fieldData: [] }

    console.log("CLD_CHECK_LIST_JSON", that.formData.CLD_CHECK_LIST_JSON);


    if (that.formData.CLD_EQUIPEMENTS && that.formData.CLD_EQUIPEMENTS != "")
      that.formData.CLD_EQUIPEMENTS = JSON.parse(that.formData.CLD_EQUIPEMENTS)

    engine.get_societe_soft_by_id(this.formData["CLD_ID_SOCIETE"], function (data) {
      if (data.code == 0) {
        that.societe = data.data.data[0].fieldData;
      }
    });


    this.agency = this.agencyList.find((val) => val.ID_Agence == this.formData['CLD_ID_AGENCE'])
    engine.get_form_special_data(function (data) {
      if (data.code == 0) {
        let personalizedDataArray = data.data.data.map((val) => val.fieldData);
        that.personalizedData = personalizedDataArray
      }
      that.reloadObservationPic()
    });

    let forcedReturn = false

    if (that.formData['CLD_CONTROLE_DEPART'] == 1 && that.formData['CLD_CHECK_LIST_JSON_GESTION'] == "")
      forcedReturn = true


    if (that.formData['CLD_CONTROLE_DEPART'] == 0 || forcedReturn) {
      let accBasicFormat = that.formData.CLD_CHECK_LIST_JSON.fieldData
      let accListe = []


      for (let i = 0; i < accBasicFormat.length; i++) {
        let elem = {
          CH_DESIGNATION: accBasicFormat[i].CH_DESIGNATION,
          CLD_QTE_DEPART: forcedReturn == false ? ((accBasicFormat[i].CH_VALEUR && accBasicFormat[i].CH_VALEUR.length > 0) ? accBasicFormat[i].CH_VALEUR : (this.defaultFormValues[0])) : undefined,
          // CLD_QTE_DEPART: forcedReturn == false ? accBasicFormat[i].CH_VALEUR : undefined,
          CLD_QTE_RETOUR: accBasicFormat[i].CH_VALEUR,
          CLD_SELECTION: that.defaultFormValues.join(',')
        }

        if (accBasicFormat[i].CLD_SELECTION && accBasicFormat[i].CLD_SELECTION.length >= 0)
          elem['CLD_SELECTION'] = accBasicFormat[i].CLD_SELECTION
        else
          elem['CLD_SELECTION'] = that.defaultFormValues.join(',')

        accListe.push(elem)
      }

      if (accListe && accListe.length > 0) {
        that.formData["CLD_CHECK_LIST_JSON_GESTION"] = accListe
      }
      else {
        // that.formData["CLD_CHECK_LIST_JSON_GESTION"] = [{ 'CH_DESIGNATION': 'État général', 'CLD_QTE_DEPART': that.defaultFormValues[0], 'CLD_QTE_RETOUR': that.defaultFormValues[0], 'CLD_SELECTION': that.defaultFormValues.join(',') }]
        that.formData["CLD_CHECK_LIST_JSON_GESTION"] = []
        //POUR SAUMUR
        engine.get_form_by_sub_family(this.formData.CLD_SOUS_FAMILLE, function (data) {
          if (data.code == 0) {
            let sortedData = {}
            let localForm = [];
            for (const [key, value] of Object.entries(data.data.data[0].fieldData)) {
              if (key.includes('(') && key.includes(')')) {
                let tmpKey = key.split('(')[0]
                if (sortedData[tmpKey] == undefined)
                  sortedData[tmpKey] = []
                sortedData[tmpKey].push(value)
              }
            }
            for (let i = 0; i < sortedData['name'].length; i++) {
              if (sortedData['name'][i].length > 0) {
                let selection = sortedData['selection'][i]
                let findedSelection = that.defaultForm.find((val) => val.CH_DESIGNATION == sortedData['name'][i])
                if (findedSelection) {
                  selection = findedSelection.CLD_SELECTION
                }
                localForm.push({
                  CH_DESIGNATION: sortedData['name'][i],
                  CLD_QTE_DEPART: forcedReturn == false ? sortedData['default'][i] : undefined,
                  CLD_QTE_RETOUR: sortedData['default'][i],
                  CLD_SELECTION: selection
                })
              }
            }
            that.options_table_data = localForm
          }
          else {
            that.options_table_data = that.defaultForm
          }
          that.formData["CLD_CHECK_LIST_JSON_GESTION"] = that.options_table_data.concat(that.formData["CLD_CHECK_LIST_JSON_GESTION"])
        })
        // that.data.data["CLD_CHECK_LIST_JSON_GESTION"] = that.options_table_data;
      }
    }
    else {
      let tmpGestion = JSON.parse(that.formData['CLD_CHECK_LIST_JSON_GESTION'])
      if (that.formData['CLD_CONTROLE_DEPART'] == 1 && that.formData['CLD_CONTROLE_RETOUR'] == 0)
        for (let i = 0; i < tmpGestion.length; i++) {
          if (tmpGestion[i].CLD_QTE_DEPART != tmpGestion[i].CLD_QTE_RETOUR) {
            tmpGestion[i].CLD_QTE_RETOUR = tmpGestion[i].CLD_QTE_DEPART
          }
        }
      that.formData['CLD_CHECK_LIST_JSON_GESTION'] = tmpGestion
    }
    that.initData()
    that.getEmailList(that.formData['CLD_ID_CLIENT'])
  },
  props: {
    parcListe: {},
    savedSignature: {},
    setSignataire: {},
    showErrorInContrat: {},
    showSignByMail: {},
    multipleSelection: {},
    drawInObservation: {},
    nbrUploadDone: {},
    getNbrPhotoToLoad: {},
    isAdmin: {},
    nbrMaxLoadedPhoto: {},
    nbrMinLoadedPhoto: {},
    defaultFormValues: {},
    agencyList: {},
    data: { required: true },
    // clients_list: {},
    user_information: {},
    defaultForm: { required: true },
  },
  computed: {
    getparcListeMatricul() {
      if (this.parcListe) {
        return this.parcListe.map((val) => ({ 'value': val.MATRICULE, 'title': val.MATRICULE, 'family': val.FAMILLE, 'subFamily': val.SOUS_FAMILLE }))
      }
      return undefined
    },
    get_tab_header() {
      let header = [{ value: "CLD_QTE_DEPART", text: "État de départ" }, { value: "CH_DESIGNATION", text: "" }, { value: "CLD_QTE_RETOUR", text: "État de retour" }]
      return header
    },
    // getqCodeUrl() {
    //   let query =
    //     this.data.data.CLD_NUM_CONTRAT + "|-|" + this.data.data.CLD_MATRICULE;
    //   return query;
    // },
    changeBackground() {
      if (
        this.showEditableBtn &&
        this.sortieDisabled === false &&
        this.entrerDisabled === false
      )
        return true;
      return false;
    },
    isMobile() {
      return this.$vuetify.display.mobile;
    },
    get_user_name() {
      return this.user_information.user_name;
    },
    getPhoto() {
      let photos = [
        {
          key: "CLD_PHOTO_1",
          tmpKey: "CLD_PHOTO_1_TMP",
          label: "Photos de départ",
          class: "sortie",
          disabled: this.sortieDisabled,
        },
        {
          key: "CLD_PHOTO_2",
          tmpKey: "CLD_PHOTO_2_TMP",
          label: "Photos de retour",
          class: "entrer",
          disabled: this.entrerDisabled,
        },
      ];
      return photos;
    },
    getComplementInfo() {
      let info = [];

      let tabPersonalizedData = Object.values(this.personalizedData)
      for (let i = 0; i < tabPersonalizedData.length; i++) {
        if (tabPersonalizedData[i].show == 1) {
          info.push(
            {
              key: tabPersonalizedData[i].key_depart,
              label: tabPersonalizedData[i].label_depart,
              class: "sortie",
              type: tabPersonalizedData[i].type,
              disabled: this.sortieDisabled,
            },
            {
              key: tabPersonalizedData[i].key_retour,
              label: tabPersonalizedData[i].label_retour,
              class: "entrer",
              type: tabPersonalizedData[i].type,
              disabled: this.entrerDisabled,
            }
          );
        }
      }
      return info;
    },
    getHiddenComment() {
      let informations = [
        {
          key: "CLD_COMMENTAIRE_LOCATION",
          label: "Commentaire interne",
          disabled: true,
          class: "termine",
        },
      ];
      return informations;
    },
    getComment() {
      let informations = [
        {
          key: "CLD_COMMENTAIRE_TECH_DEPART",
          label: "Commentaire de livraison départ",
          disabled: true,
          class: "sortie",
        },
        {
          key: "CLD_COMMENTAIRE_TECH_RETOUR",
          label: "Commentaire de livraison retour",
          disabled: true,
          class: "entrer",
        }
      ];
      return informations;
    },
    getInformation() {
      let informations = {};
      informations["data"] = [
        {
          key: "CLD_AGENCY",
          label: "Agence",
          disabled: true,
        },
        {
          key: "CLD_NUM_CONTRAT",
          label: "N° Contrat",
          disabled: true,
        },
        {
          key: "CLD_TEL_LIV",
          label: "Numéro de téléphone",
          disabled: true,
        },
        {
          key: "CLD_CONTACT_LIV",
          label: "Contact de livraison",
          disabled: true,
        }
      ];
      informations["description"] = {
        key: "CLD_DESCRIPTION",
        label: "Description",
        disabled: true,
      };

      return informations;
    },
    getObservations() {
      let observations = [
        {
          key: "CLD_COMMENTAIRE_DEPART",
          label: "Observation de départ",
          class: "sortie",
          disabled: this.sortieDisabled,
        },
        {
          key: "CLD_COMMENTAIRE_RETOUR",
          label: "Observation de retour",
          class: "entrer",
          disabled: this.entrerDisabled,
        },
      ];
      return observations;
    },
    getDates() {
      let dates = [
        {
          key: "CLD_DATE_DEPART_VALIDATION",
          label: "Date de départ",
          open: false,
          class: "sortie",
          disabled: this.sortieDisabled,
        },
        {
          key: "CLD_DATE_RETOUR_VALIDATION",
          label: "Date de retour",
          open: false,
          class: "entrer",
          disabled: this.entrerDisabled,
        },
      ];
      return dates;
    },
  },
  components: {
    WifiState,
    Datepicker,
    // QrcodeVue,
    SignaturePad,
    FormOptionsTable
    // Vue3EasyDataTable
  },
  methods: {
    getLvl(lvl) {
      this.networkLvl = lvl
    },
    initData() {
      let that = this
      this.mainClasse = "termine";
      let index = Object.values(this.data.disabled).findIndex(
        (data) => data === false
      );
      if (index !== -1) {
        this.mainClasse = Object.keys(this.data.disabled)[index];
      }

      // this.formaredClientList = JSON.parse(JSON.stringify(this.clients_list));
      // this.formaredClientList = this.formaredClientList.map((val) => val.data);

      this.sortieDisabled = this.data.disabled.sortie;
      this.entrerDisabled = this.data.disabled.entrer;
      if ((this.sortieDisabled && this.entrerDisabled) || this.sortieDisabled) {
        this.showEditableBtn = true;
      }

      if (this.showErrorInContrat == true) {
        if (this.formData['CLD_ERREUR'] == 1)
          this.formData['CLD_ERREUR'] = true
        else
          this.formData['CLD_ERREUR'] = false
      }

      window.addEventListener('resize', function () {
        that.reloadObservationPic()
      })
      this.setParcList();

      if (this.entrerDisabled == false) {
        if (this.formData["CLD_CONTROLEUR_RETOUR"] == undefined || this.formData["CLD_CONTROLEUR_RETOUR"] == "" || this.formData["CLD_CONTROLEUR_RETOUR"].length == 0)
          this.formData["CLD_CONTROLEUR_RETOUR"] = this.get_user_name;
        if (this.setSignataire)
          if (this.formData["CLD_SIGNATAIRE_RETOUR"] == undefined || this.formData["CLD_SIGNATAIRE_RETOUR"] == "" || this.formData["CLD_SIGNATAIRE_RETOUR"].length == 0)
            this.formData["CLD_SIGNATAIRE_RETOUR"] = this.formData["CLD_CLIENT_RAISON_SOCIALE"];
      }
      if (this.sortieDisabled == false) {
        if (this.formData["CLD_CONTROLEUR_DEPART"] == undefined || this.formData["CLD_CONTROLEUR_DEPART"] == "" || this.formData["CLD_CONTROLEUR_DEPART"].length == 0)
          this.formData["CLD_CONTROLEUR_DEPART"] = this.get_user_name;
        if (this.setSignataire == true)
          if (this.formData["CLD_SIGNATAIRE_DEPART"] == undefined || this.formData["CLD_SIGNATAIRE_DEPART"] == "" || this.formData["CLD_SIGNATAIRE_DEPART"].length == 0)
            this.formData["CLD_SIGNATAIRE_DEPART"] = this.formData["CLD_CLIENT_RAISON_SOCIALE"];
      }

      if (this.formData['CLD_ID_CLIENT'] && this.formData['CLD_ID_CLIENT'].length > 0) {
        engine.get_clients_SS_by_id(this.formData['CLD_ID_CLIENT'], function (data) {
          if (data.code == 0) {
            let res = data.data.data[0].fieldData
            that.emailSignatureListe = res.CONTACT_MAIL_TOUS.split('|').filter((val) => val.length > 0)
          }
        })
      }

      that.init();
    },
    addEmailToListe() {
      this.emailSignatureListe.push(this.email_pdf)
      this.email_pdf = undefined
    },
    deleteEmailToListe(idx) {
      this.emailSignatureListe.splice(idx, 1)
    },
    imageExists(image_url) {
      var http = new XMLHttpRequest();
      http.open('HEAD', image_url, false);
      http.send();
      return http.status != 404;
    },
    reloadObservationPic() {
      if (this.formData["CLD_OBSERVATION_PHOTO_DEPART"] && this.formData["CLD_OBSERVATION_PHOTO_DEPART"].length > 0)
        this.write('signaturePadObservationsortie', this.formData["CLD_OBSERVATION_PHOTO_DEPART"])
      if (this.formData["CLD_OBSERVATION_PHOTO_RETOUR"] && this.formData["CLD_OBSERVATION_PHOTO_RETOUR"].length > 0)
        this.write('signaturePadObservationentrer', this.formData["CLD_OBSERVATION_PHOTO_RETOUR"])
    },
    uploadPhoto(data, key, openViewer) {
      let keyTrade = {
        CLD_PHOTO_1: { name: "DEPART", label: "CLD_PHOTO_1" },
        CLD_PHOTO_2: { name: "RETOUR", label: "CLD_PHOTO_2" },
      };
      let path =
        "LOCATION/" +
        this.formData["CLD_NUM_CONTRAT"] +
        "/" +
        this.formData["CLD_MATRICULE"].replaceAll("/", "@") +
        "/" +
        "PHOTOS/";

      this.selectedPhotoTypeByClass = data.class;
      this.photoViewerDataKey = key;
      if (this.nbrPhoto[key] == undefined && this.nbrPhotoFinded[key] && (this.nbrPhoto[key] == undefined || this.nbrPhoto[key] < this.nbrPhotoFinded[key])) {
        this.isPhotoLoading = true
        this.getFilesListFromFolderByPath(path, true, keyTrade[key].name)
      }
      else {
        if (openViewer == true)
          this.photoViewer = true;
      }
    },
    selectChange(label, key, value) {
      this.formData.CLD_CHECK_LIST_JSON_GESTION.find((val) => val['CH_DESIGNATION'] == label)[key] = value
    },
    init() {
      if (
        this.formData["CLD_DATE_DEPART_VALIDATION"] &&
        this.formData["CLD_DATE_RETOUR_VALIDATION"] == ""
      ) {
        this.formData["CLD_DATE_RETOUR_VALIDATION"] =
          moment().format("YYYY-MM-DD");
      }
      if (this.formData["CLD_DATE_DEPART_VALIDATION"] == "")
        this.formData["CLD_DATE_DEPART_VALIDATION"] = moment();
      if (this.formData["CLD_DATE_DEPART_VALIDATION"])
        this.formData["CLD_DATE_DEPART_VALIDATION"] = moment(
          this.formData["CLD_DATE_DEPART_VALIDATION"]
        ).format("YYYY-MM-DD");
      if (this.formData["CLD_DATE_RETOUR_VALIDATION"])
        this.formData["CLD_DATE_RETOUR_VALIDATION"] = moment(
          this.formData["CLD_DATE_RETOUR_VALIDATION"]
        ).format("YYYY-MM-DD");
    },
    changeLoad() {
      this.$emit('pdfLoaded')
    },
    formatDate(date, format) {
      let momentDate = moment(date).format(format);
      return momentDate
    },
    getFilesListFromFolderByPath(filePath, savePics, keyToDownload) {
      let that = this
      engine.photo_exite(filePath, function (data) {

        if (data.code == 0) {
          that.nbrPhotoFinded['CLD_PHOTO_1'] = data.data.filter((val) => val.includes('DEPART'))
          that.nbrPhotoFinded['CLD_PHOTO_2'] = data.data.filter((val) => val.includes('RETOUR'))
          if (that.nbrPhotoFinded['CLD_PHOTO_1'] && that.nbrPhotoFinded['CLD_PHOTO_1'].length > 0) {
            that.nbrPhotoFinded['CLD_PHOTO_1'] = that.nbrPhotoFinded['CLD_PHOTO_1'].length
          } else {
            that.nbrPhotoFinded['CLD_PHOTO_1'] = 0
          }
          if (that.nbrPhotoFinded['CLD_PHOTO_2'] && that.nbrPhotoFinded['CLD_PHOTO_2'].length > 0) {
            that.nbrPhotoFinded['CLD_PHOTO_2'] = that.nbrPhotoFinded['CLD_PHOTO_2'].length
          } else {
            that.nbrPhotoFinded['CLD_PHOTO_2'] = 0
          }
          if (savePics)
            that.downloadPicsFromPaths(filePath, data.data.filter((val) => val.includes(keyToDownload)), 0)
        }
        that.isLoading = false
        that.reloadObservationPic()
      })
    },
    downloadPicsFromPaths(mainPath, fileNameListe, idx) {
      let that = this
      let keyTrade = {
        DEPART: "CLD_PHOTO_1",
        RETOUR: "CLD_PHOTO_2",
      };

      if (idx < fileNameListe.length) {
        let fileName = fileNameListe[idx]
        let path = mainPath + fileName
        let key = keyTrade[fileName.split('_')[0]]
        engine.photo_download(path, function (data) {
          if (data.code == 0) {
            let blob = new Blob(
              [new Uint8Array(data.data.result.fileBinary.data)],
              { type: "image/png" }
            );
            if (that.photo_upload_data[key] == undefined)
              that.photo_upload_data[key] = {};
            that.photo_upload_data[key][idx] = data.data.result
            const reader = new FileReader();
            reader.onloadend = () => {
              let url = reader.result;
              if (that.formData[key] == "")
                that.formData[key] = {};
              that.formData[key][idx] = url;
              that.formData[key + "_TMP"] = new Array(
                Object.keys(that.formData[key]).length
              ).fill({});
              that.nbrPhoto[key] =
                that.formData[key + "_TMP"].length;
            };
            reader.readAsDataURL(blob);
            that.downloadPicsFromPaths(mainPath, fileNameListe, idx + 1)
          } else {
            that.downloadPicsFromPaths(mainPath, fileNameListe, idx)
          }
        });
      } else {
        that.isPhotoLoading = false
        that.photoViewer = true;
      }
    },
    getPhotoFromDropBox() {
      this.formData["CLD_PHOTO_1"] = {};
      this.formData["CLD_PHOTO_2"] = {};
      // let keyTrade = {
      //   CLD_PHOTO_1: { key: "DEPART", label: "CLD_PHOTO_1" },
      //   CLD_PHOTO_2: { key: "RETOUR", label: "CLD_PHOTO_2" },
      // };

      let path =
        "LOCATION/" +
        this.formData["CLD_NUM_CONTRAT"] +
        "/" +
        this.formData["CLD_MATRICULE"].replaceAll("/", "@") +
        "/" +
        "PHOTOS/";

      this.getFilesListFromFolderByPath(path, false, undefined)
      // this.recursiveDowloadPhoto(path, 0, Object.values(keyTrade)[0]);
      // this.recursiveDowloadPhoto(path, 0, Object.values(keyTrade)[1]);
    },
    File_Upload() {
      this.save_html_into_pdf(false, true);
    },
    getEmailList(clientId) {
      let that = this;

      engine.get_email_liste_by_id_client(clientId, function (data) {

        if (data.code == 0) {
          that.emailList = data.data.data[0].fieldData["CONTACT_MAIL_TOUS"]
            .split("|")
            .filter((val) => val !== "");
        }
        if (that.emailList == undefined) {
          that.emailList = []
        }
        if (that.formData['CLD_CLIENT_MAILS'] && that.formData['CLD_CLIENT_MAILS'].length > 0) {
          that.emailList.push(that.formData['CLD_CLIENT_MAILS'])
          that.email_pdf = that.emailList[0]
        }
      });
    },
    reLoadForm() {
      window.location =
        window.location.href +
        "?contrat=" +
        this.formData["CLD_NUM_CONTRAT"] +
        "&matricule=" +
        this.formData["CLD_MATRICULE"];
      location.reload();
    },
    formIsEditable() {
      let that = this;
      if (that.mainClasse === "termine") {
        that.entrerDisabled = !that.entrerDisabled;
        that.sortieDisabled = !that.sortieDisabled;
      } else if (that.mainClasse === "entrer") {
        if (that.sortieDisabled) {
          that.entrerDisabled = false;
          that.sortieDisabled = false;
        } else {
          that.entrerDisabled = false;
          that.sortieDisabled = true;
        }
      }
      that.rootModal = false;
      that.rootErrorMessage = undefined;
    },
    deletePic(index, key) {
      delete this.formData[this.photoViewerDataKey + "_TMP"][index];
      delete this.formData[this.photoViewerDataKey][index];
      this.nbrPhoto[key] = this.nbrPhoto[key] - 1;

      if (this.nbrPhoto[key] < 0) this.nbrPhoto[key] = 0;
      this.formData[this.photoViewerDataKey + "_TMP"] =
        this.formData[this.photoViewerDataKey];

      this.photoViewer = false;
      this.photoViewer = true;
    },
    openHandler(pdfApp) {
      window._pdfApp = pdfApp;
    },
    writeSignature(ref, disabled) {
      if (disabled === false) {
        this.selectedSignature = ref;
        this.isSignatureOpen = true;
      }
    },

    askSignature() {
      // this.isAskSignatureOpen = true
      this.save_html_into_pdf(false, false, true);
    },
    downloadPdf() {
      this.save_html_into_pdf(true);
    },
    sharePdf() {
      this.save_html_into_pdf(false);
    },
    send_pdf(addLink) {
      let that = this
      let photo = { "depart": this.formData["CLD_PHOTO_1"], "retour": this.formData["CLD_PHOTO_2"] }
      let info = {
        contrat: this.formData["CLD_NUM_CONTRAT"], matricule: this.formData["CLD_MATRICULE"], state: this.mainClasse,
        etadlData: { CLD_CONTROLE_RETOUR: this.formData.CLD_CONTROLE_RETOUR, CLD_CONTROLE_DEPART: this.formData.CLD_CONTROLE_DEPART },
      }

      if (addLink == true) {
        engine.send_signature_request(
          this.emailSignatureListe,
          this.pdfLink,
          info,
          photo,
          function (data) {
            if (data.data.errorListe.length == 0) {
              that.snackbar.text = 'Email(s) envoyé(s) !'
              that.snackbar.color = '#66BB6A'
              that.snackbar.value = true
            }
            else {
              that.snackbar.text = 'Erreur rencontré sur le(s) email(s) : ' + data.data.errorListe.join(' - ')
              that.snackbar.color = '#E57373'
              that.snackbar.value = true
            }
          }
        );
      }
      else
        engine.send_pdf_by_email(
          this.email_pdf,
          this.pdfLink,
          this.formData["CLD_NUM_CONTRAT"],
          this.formData["CLD_AGENCY"],
          photo,
          function (data) {
            if (data.code == 0) {
              that.snackbar.text = 'Email envoyé !'
              that.snackbar.color = '#66BB6A'
              that.snackbar.value = true
            } else {
              that.snackbar.text = 'Erreur l\'émail  n\'a pas été envoyé !'
              that.snackbar.color = '#E57373'
              that.snackbar.value = true
            }
          }
        );
      this.sendEmail = false;
      this.isAskSignatureOpen = false
    },
    setPhoto1InPhoto2() {
      this.formData["CLD_PHOTO_2"] = this.formData["CLD_PHOTO_1"];
      this.formData["CLD_PHOTO_2_TMP"] = this.formData["CLD_PHOTO_1_TMP"];
      this.nbrPhoto["CLD_PHOTO_2"] = this.nbrPhoto["CLD_PHOTO_1"];
      this.modalSetPhoto2 = false;
    },
    write(key, data) {
      if (this.$refs[key]) {
        if (Array.isArray(this.$refs[key]) && this.$refs[key][0])
          this.$refs[key][0].fromDataURL(data);
        else {
          if (this.$refs[key])
            this.$refs[key].fromDataURL(data);
        }
      }
    },
    undo(key) {
      if (this.$refs[key].length == undefined)
        this.$refs[key].undoSignature();
      else
        this.$refs[key][0].undoSignature();
    },
    save(key) {
      const { isEmpty, data } = this.$refs[key].saveSignature();
      if (isEmpty === false) this.formData[key] = data;
    },
    onInput(key, save) {
      const { isEmpty, data } = this.$refs[key][0].saveSignature();
      let tabKeys = {
        "signaturePadObservationsortie": "CLD_OBSERVATION_PHOTO_DEPART",
        "signaturePadObservationentrer": "CLD_OBSERVATION_PHOTO_RETOUR"
      }
      if (isEmpty === false && save) {
        this.formData[tabKeys[key]] = data;
      }
    },
    clear(key) {
      if (this.$refs[key].length == undefined)
        this.$refs[key].clearSignature();
      else
        this.$refs[key][0].clearSignature();
    },
    isCanvasBlank(canvas) {
      const blank = document.createElement('canvas');

      blank.width = canvas.width;
      blank.height = canvas.height;

      return canvas.toDataURL() === blank.toDataURL();
    },

    save_html_into_pdf(download, getUlr, sendSignature, saveInLocal) {
      window.scrollTo(0, 0);
      let that = this;

      if (getUlr == undefined || getUlr == false || saveInLocal) {
        that.isPrinting = true;
      }
      // let qcode = document.querySelector("#qcode");
      // qcode.style.display = "block";

      let info = document.querySelectorAll("#info");
      for (let i = 0; i < info.length; i++) info[i].style.display = "block";

      let elms = document.querySelectorAll("#hideInPrint");
      for (let i = 0; i < elms.length; i++) elms[i].style.display = "none";

      let data = document.querySelector("#captureMateriel");
      data.style.width = "1000px";

      let form = document.querySelector("#formContainer");
      form.style.height = "auto";
      // data.style.backgroundColor = "white";

      // data.style.border = "solid 1px lightgrey";
      let html2canvasOptions = {
        allowTaint: true,
        removeContainer: true,
        backgroundColor: null,
        imageTimeout: 15000,
        logging: true,
        scale: 2,
        useCORS: true,
        processData: false,
        contentType: false,
        windowWidth: 1600,
        // windowHeight: 1600,
      };
      html2canvas(data, html2canvasOptions).then(async (canvas) => {
        // optionsTab.style.display = 'block'
        var imgData = await canvas.toDataURL("image/png");
        var imgWidth = 204;
        var imgHeight = 590;
        var position = 3;
        var pageHeight = 300
        var heightLeft = imgHeight - pageHeight

        var doc = new jsPDF("p", "mm");
        doc.addImage(
          imgData,
          "PNG",
          3,
          position,
          imgWidth,
          imgHeight,
          undefined,
          "FAST"
        );

        while (heightLeft >= 0) {
          position = heightLeft - imgHeight + 10;
          doc.addPage();
          doc.addImage(imgData, 'PNG', 3, position, imgWidth, imgHeight, undefined, 'FAST');
          heightLeft -= pageHeight;
        }

        data.style.width = "auto";
        form.style.height = "70vh";
        for (let i = 0; i < info.length; i++) info[i].style.display = "none";
        that.isPrinting = false;
        that.pdfLink = doc.output("datauristring");
        for (var i = 0; i < elms.length; i++) {
          elms[i].style.display = "block";
        }
        if (sendSignature && sendSignature == true) {
          that.isAskSignatureOpen = true
          that.isPrinting = false;
        }
        else if (saveInLocal != true && (getUlr == undefined || getUlr == false)) {
          if (download) {
            let id = uuidv4();
            engine.creat_tmp_pdf(id, doc.output(), function (data) {
              if (data.code === 0) {
                engine.openPdf(id, function (urlPdf) {
                  Browser.open({ url: urlPdf });
                  that.reloadObservationPic()
                });
              }
            });
          } else {
            that.sendEmail = true;
          }
        } else {
          let dropBoxPath =
            "LOCATION/" +
            that.formData["CLD_NUM_CONTRAT"] +
            "/" +
            that.formData["CLD_MATRICULE"].replaceAll("/", "@") +
            "/" +
            that.data.title.toUpperCase() +
            ".pdf";

          // that.saveCopyDataAsMail()

          that.$emit("newMaterielForm", {
            data: that.formData,
            edit: that.changeBackground,
            networkLvl: that.networkLvl,
            isForced: false,
            etatdlPdf: {
              pdfLink: that.pdfLink,
              dropBoxPath: dropBoxPath,
            }
          });
          that.isPrinting = false;

          // engine.file_upload(that.pdfLink, dropBoxPath, function () {
          //   that.changeLoad();
          //   // that.reloadObservationPic()
          // });
        }
      });
    },
    setParcList() {
      let that = this;
      // engine.get_parcs(this.formData["CLD_MATRICULE"], function (data) {
      //   if (data.code == 0) {
      //     let parcList = data.data.data.map((val) => val.fieldData);

      that.parc_information = this.parcListe.find(
        (val) => val.MATRICULE == that.formData["CLD_MATRICULE"]
      );

      if (that.parc_information) {
        delete that.parc_information.id;
        delete that.parc_information.MODIFICATION;
        delete that.parc_information.CREATION;
        delete that.parc_information.CARA;
        // delete that.parc_information.FAMILLE;
        delete that.parc_information.AFFECTATION;
      }


      //   }
      // });
    },

    updatePhotos(data, key, tmpkey) {
      this.getPhotoSrc([data], key, tmpkey);
    },

    resizeBase64Img(base64) {
      return new Promise((resolve) => {
        var canvas = document.createElement("canvas");
        let img = document.createElement("img");
        // let quality = 1;
        img.src = base64;

        img.onload = function () {
          // let imgWidth = this.width / quality;
          // let imgHeight = this.height / quality;
          let imgWidth = 720;
          let imgHeight = 720;
          canvas.width = imgWidth;
          canvas.height = imgHeight;
          var context = canvas.getContext("2d");
          context.drawImage(img, 0, 0, imgWidth, imgHeight);
          resolve(canvas.toDataURL());
        };
      });
    },

    getPhotoSrc(photosList, key, tmpkey) {
      let that = this;
      let oldPic = this.formData[key];
      let index = Object.keys(this.formData[key]).length;
      if (
        (photosList && this.formData[tmpkey].length > this.nbrMaxLoadedPhoto) ||
        index >= this.nbrMaxLoadedPhoto
      ) {
        this.formData[tmpkey] = this.formData[tmpkey].slice(
          0,
          this.nbrMaxLoadedPhoto
        );
        this.errorMsg =
          "Nombre maximum de photos sélectionnées " +
          this.nbrMaxLoadedPhoto;
        this.showAlert = true;
      } else {
        if (index == 0) this.formData[key] = {};
        else {
          let a = 0;
          for (const value of Object.values(oldPic)) {
            this.formData[key][a] = value;
            a += 1;
          }
        }
        for (let i = 0; i < photosList.length && i < that.nbrMaxLoadedPhoto; i++) {
          let file = photosList[i][0];
          var reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = function (e) {
            that.resizeBase64Img(e.target.result).then((result) => {
              that.formData[key][i + Object.keys(that.formData[key]).length] =
                result;
              that.nbrPhoto[key] = Object.keys(that.formData[key]).length;
            });
          };
        }
      }
    },
    openPhotoViewer(data, key) {
      this.uploadPhoto(data, key, true)
      // let keyTrade = {
      //   CLD_PHOTO_1: { name: "DEPART", label: "CLD_PHOTO_1" },
      //   CLD_PHOTO_2: { name: "RETOUR", label: "CLD_PHOTO_2" },
      // };
      // let path =
      //   "LOCATION/" +
      //   this.data.data["CLD_NUM_CONTRAT"] +
      //   "/" +
      //   this.data.data["CLD_MATRICULE"].replaceAll("/", "@") +
      //   "/" +
      //   "PHOTOS/";

      // this.selectedPhotoTypeByClass = data.class;
      // this.photoViewerDataKey = key;
      // if (this.nbrPhoto[key] == undefined || this.nbrPhoto[key] < this.nbrPhotoFinded[key]) {
      //   this.isPhotoLoading = true
      //   this.getFilesListFromFolderByPath(path, true, keyTrade[key].name)
      // } else {
      //   this.photoViewer = true;
      // }
    },
    boolToDisplay(bool) {
      if (bool === true) {
        return "block";
      }
      return "none";
    },
    convertBoolean(val) {
      if (val) return "Oui";
      return "Non";
    },

    isVerifNbrPhotoGood(key) {
      let verif = true

      if (this.nbrPhoto[key] && this.nbrPhoto[key] > 0)
        this.nbrPhotoFinded[key] = this.nbrPhoto[key]

      if (this.nbrPhotoFinded[key] == undefined && this.nbrPhoto[key] == undefined)
        verif = false
      if (
        (this.nbrPhotoFinded[key] == undefined || this.nbrPhotoFinded[key] < this.nbrMinLoadedPhoto) &&
        (this.nbrPhoto[key] == undefined || this.nbrPhoto[key] < this.nbrMinLoadedPhoto))
        verif = false
      return verif
    },

    enterFieldsAreFilled() {
      console.log("enterFieldsAreFilled");

      let requireFields = [
        "CLD_DATE_RETOUR_VALIDATION",
        "CLD_CONTROLEUR_RETOUR",
        // "CLD_SIGNATURE_RETOUR",
        // "CLD_SIGNATAIRE_RETOUR",
        // "CLD_QTE_RETOUR",
      ];

      if (this.isSignataireNotDisponible['retour'] == false) {
        requireFields.push("CLD_SIGNATAIRE_RETOUR")
        requireFields.push("CLD_SIGNATURE_RETOUR")
      }

      console.log(0);

      let personalizedDataObj = this.personalizedData.filter((val) => val.show == 1)
      if (personalizedDataObj) {
        for (let i = 0; i < personalizedDataObj.length; i++) {

          if (personalizedDataObj[i].mandatory == 1)
            requireFields.push(personalizedDataObj[i].key_retour);
        }
      }

      console.log(1);

      // if (this.personalizedData["ADBLUE"])
      //   requireFields.push("CLD_NIVEAU_ADBLUE_RETOUR");
      // if (this.personalizedData["CARBURANT"])
      //   requireFields.push("CLD_NIVEAU_GAS_RETOUR");
      // if (this.personalizedData["KM"])
      //   requireFields.push("CLD_KILOMETRE_RETOUR");
      // if (this.personalizedData["HORAMETRE"])
      //   requireFields.push("CLD_HORAMETRE_RETOUR");

      let emptyOption = this.formData["CLD_CHECK_LIST_JSON_GESTION"].find(
        (val) => val.CLD_QTE_RETOUR == ""
      );

      if (emptyOption) {
        this.errorMsg =
          "Option de retour  " + emptyOption.CH_DESIGNATION + " manquante";

        this.showAlert = true;
        return false;
      }
      console.log(2);

      if (
        !this.isVerifNbrPhotoGood('CLD_PHOTO_2') && this.nbrMinLoadedPhoto && this.nbrMinLoadedPhoto > 0
      ) {
        this.errorMsg =
          "Minimum de " + this.nbrMinLoadedPhoto + " photos obligatoire !";
        this.showAlert = true;
        return false;
      }
      console.log(3);

      if (requireFields.includes("CLD_KILOMETRE_RETOUR")) {
        if (
          parseFloat(this.formData["CLD_KILOMETRE_RETOUR"]) <
          parseFloat(this.formData["CLD_KILOMETRE_DEPART"])
        ) {
          this.errorMsg =
            "Nombre de kilomètre de départ supérieur au nombre de kilometre de retour.";
          this.showAlert = true;
          return false;
        }
      }
      console.log(4);

      if (requireFields.includes("CLD_HORAMETRE_RETOUR")) {
        if (
          parseFloat(this.formData["CLD_HORAMETRE_RETOUR"]) <
          parseFloat(this.formData["CLD_HORAMETRE_DEPART"])
        ) {
          this.errorMsg =
            "Compteur horaire de départ supérieur au compteur horaire de retour.";
          this.showAlert = true;
          return false;
        }
      }
      console.log(5);

      for (let i = 0; i < requireFields.length; i++) {
        if (
          this.formData[requireFields[i]] === undefined ||
          this.formData[requireFields[i]] === ""
        ) {

          this.errorMsg =
            this.home[requireFields[i]] +
            " manquant(e)";
          this.showAlert = true;
          return false;
        }
      }
      return true;
    },

    sortieFieldsAreFilled() {
      console.log("sortieFieldsAreFilled");

      let requireFields = [
        "CLD_NUM_CONTRAT",
        "CLD_DATE_DEPART_VALIDATION",
        "CLD_CONTROLEUR_DEPART",
        // "CLD_SIGNATURE_DEPART",
        // "CLD_SIGNATAIRE_DEPART",
        // "CLD_QTE_DEPART",
      ];

      console.log(0);

      if (this.isSignataireNotDisponible['depart'] == false) {
        requireFields.push("CLD_SIGNATAIRE_DEPART")
        requireFields.push("CLD_SIGNATURE_DEPART")
      }
      console.log(1);

      let personalizedDataObj = []

      console.log("personalizedData", this.personalizedData, this.personalizedData.length > 0);

      if (this.personalizedData && this.personalizedData.length > 0) {
        personalizedDataObj = this.personalizedData.filter((val) => val.show == 1)
        if (personalizedDataObj) {
          for (let i = 0; i < personalizedDataObj.length; i++) {
            if (personalizedDataObj[i].mandatory == 1)
              requireFields.push(personalizedDataObj[i].key_depart);
          }
        }
      }
      console.log(2);

      // if (this.personalizedData["ADBLUE"])
      //   requireFields.push("CLD_NIVEAU_ADBLUE_DEPART");
      // if (this.personalizedData["CARBURANT"])
      //   requireFields.push("CLD_NIVEAU_GAS_DEPART");
      // if (this.personalizedData["KM"])
      //   requireFields.push("CLD_KILOMETRE_DEPART");
      // if (this.personalizedData["HORAMETRE"])
      //   requireFields.push("CLD_HORAMETRE_DEPART");
      let emptyOption = this.formData["CLD_CHECK_LIST_JSON_GESTION"].find(
        (val) => val.CLD_QTE_DEPART == ""
      );

      if (emptyOption) {

        this.errorMsg =
          "Option de départ  " + emptyOption.CH_DESIGNATION + " manquante";

        this.showAlert = true;
        return false;
      }
      console.log(3);

      if (
        !this.isVerifNbrPhotoGood('CLD_PHOTO_1') && this.nbrMinLoadedPhoto && this.nbrMinLoadedPhoto > 0
        //   this.nbrPhoto["CLD_PHOTO_1"] < this.nbrMinLoadedPhoto)
      ) {
        this.errorMsg =
          "Minimum de " + this.nbrMinLoadedPhoto + " photos obligatoire !";
        this.showAlert = true;
        return false;
      }
      console.log(4);

      for (let i = 0; i < requireFields.length; i++) {
        if (
          this.formData[requireFields[i]] === undefined ||
          this.formData[requireFields[i]] === ""
        ) {
          this.errorMsg =
            this.home[requireFields[i]] +
            " " +
            "manquant(e)";
          this.showAlert = true;
          return false;
        }
      }
      console.log(5);
      return true;
    },
    logData() {
      console.log("logData");

      let dep = this.formData['CLD_CONTROLE_DEPART']
      let ret = this.formData['CLD_CONTROLE_RETOUR']

      console.log("formData", this.formData);

      if ((dep == '1' && ret == '0') && this.enterFieldsAreFilled()) {
        console.log(0);
        this.updateBdd();
      } else if ((dep == 'X' || (dep == '0' && ret == '0')) && this.sortieFieldsAreFilled()) {
        console.log(1);
        this.updateBdd();
      } else if (
        (dep == '1' && ret == '1') && this.sortieFieldsAreFilled() &&
        this.enterFieldsAreFilled()
      ) {
        console.log(2)
        this.updateBdd();
      }
      console.log("else");


    },
    updateBdd() {

      delete this.formData["CLD_PHOTO_1_TMP"];
      this.formData["CLD_PHOTO_1"] = JSON.stringify(
        this.formData["CLD_PHOTO_1"]
      );
      delete this.formData["CLD_PHOTO_2_TMP"];
      this.formData["CLD_PHOTO_2"] = JSON.stringify(
        this.formData["CLD_PHOTO_2"]
      );
      if (this.formData["CLD_DATE_DEPART_VALIDATION"] !== "")
        this.formData["CLD_DATE_DEPART_VALIDATION"] = moment(
          this.formData["CLD_DATE_DEPART_VALIDATION"]
        ).format("MM-DD-YYYY");
      if (this.formData["CLD_DATE_RETOUR_VALIDATION"] !== "")
        this.formData["CLD_DATE_RETOUR_VALIDATION"] = moment(
          this.formData["CLD_DATE_RETOUR_VALIDATION"]
        ).format("MM-DD-YYYY");
      // this.File_Upload();
      this.signatureVerification()
      this.save_html_into_pdf(false, false, false, true)
    },
    signatureVerification() {
      if (this.formData['CLD_SIGNATURE_DEPART'] && this.formData['CLD_SIGNATURE_DEPART'].length > 0) this.formData['CLD_FLAG_SIGNATAIRE_DEPART'] = 'Oui'
      else this.formData['CLD_FLAG_SIGNATAIRE_DEPART'] = ''

      if (this.formData['CLD_SIGNATURE_RETOUR'] == undefined && this.formData['CLD_SIGNATURE_RETOUR'].length > 0) this.formData['CLD_FLAG_SIGNATAIRE_RETOUR'] = 'Oui'
      else this.formData['CLD_FLAG_SIGNATAIRE_RETOUR'] = ''
    },
    closeDialog() {
      this.closeVerifIsOpen = true
      // this.$emit("closeDialog");
    },
    finalClose() {
      this.$emit("closeDialog");
    },

  },
};
</script>

<style>
.etat-des-lieux-materiel .dp__calendar {
  width: 500px !important;
  padding: 0px 10px;
}

.etat-des-lieux-materiel .photo {
  position: absolute;
  left: 5px;
  top: 6px;
}

.etat-des-lieux-materiel .photo .v-input__control {
  display: none;
}

#signature {
  border: solid 3px #eeeeee;
}

.centered-input input {
  text-align: center;
}
</style>